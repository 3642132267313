import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";


export default function Playground() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://plus.unsplash.com/premium_photo-1685366454253-cb705836c5a8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Playground{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            {" "}
            BGS World School boasts a spacious and well-equipped playground,
            fostering an active and vibrant sports culture among our students.
            This expansive space serves as a platform for students to engage in
            various sports and recreational activities, contributing to their
            physical well-being and promoting a healthy lifestyle. The
            playground is equipped for a range of sports such as football,
            cricket, basketball, and more, enabling students to pursue their
            athletic interests alongside their academic pursuits in B.Com, BBA,
            and M.Com.
          </b>
          <br />
          <br />
          The playground at BGS World School is more than just a physical space;
          it is an arena that fosters teamwork, leadership, and sportsmanship
          among students. Through various inter-collegiate and intra-collegiate
          tournaments, students have the opportunity to showcase their athletic
          prowess and build a sense of camaraderie. The memories and friendships
          made on this playground often last a lifetime. At BGS World School, we
          believe in the holistic development of our students, and our
          playground plays a crucial role in fulfilling this mission.
        </div>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            A Dynamic Space for Play and Learning
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            The playground at BGS World School is a vibrant and expansive area
            designed to support both physical activity and creative play. It
            features a range of modern equipment, including swings, slides,
            climbing structures, and sports facilities, catering to children of
            all ages. The playground’s design encourages exploration and
            imagination, with interactive play zones that stimulate cognitive
            and physical development. Safe, cushioned surfaces ensure that
            children can play freely while minimizing the risk of injury. This
            space not only provides an outlet for physical energy but also
            fosters social interaction and teamwork, essential for holistic
            child development.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Encouraging Physical Fitness and Fun
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Physical fitness is a key component of our educational
                philosophy, and the BGS World School playground is instrumental
                in promoting an active lifestyle among students. The playground
                includes various sports courts and fields for activities such as
                basketball, soccer, and athletics, encouraging students to
                engage in healthy competition and develop their athletic skills.
                Regularly scheduled sports events and physical education classes
                make full use of these facilities, helping students build
                endurance, coordination, and teamwork. By integrating fun with
                fitness, the playground supports students' overall health and
                well-being, making physical activity an enjoyable part of their
                daily routine.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                A Space for Social and Emotional Growth
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Beyond physical activity, the BGS World School playground is a
                nurturing environment for social and emotional development. It
                offers ample space for group play, where students learn to
                cooperate, negotiate, and resolve conflicts, building essential
                social skills. The playground's design includes shaded areas and
                seating where students can gather and engage in conversations,
                fostering friendships and emotional connections. Regular
                supervision by trained staff ensures a safe environment where
                students feel secure to express themselves and interact with
                peers. By providing a supportive and stimulating playground, we
                help students develop resilience, empathy, and a positive
                attitude towards physical and social challenges.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
