import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import Slider from "./Slider";



export default function About() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1622533087594-627c3aa0d68a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Facilities
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
        <b> At BGS World School, our classrooms are thoughtfully designed to provide an
          optimal learning environment for our students. Spacious, well-lit, and
          equipped with modern teaching aids, these classrooms offer a conducive
          space for intellectual engagement and interaction. Whether it’s a
          lecture for our B.Com program, a group discussion in a BBA course, or
          a seminar for M.Com students, our classrooms can adapt to varied
          teaching methods and learning preferences.</b>
          <br />
          <br />
          Beyond their physical features, our classrooms are where the
          transformational journey of learning takes place. Here, ideas are
          shared, perspectives are broadened, and critical thinking is
          cultivated. Our experienced faculty bring these rooms to life with
          their expertise, passion, and innovative teaching techniques. At
          BGS World School, we understand that the classroom experience is a crucial part
          of our students’ academic journey. Thus, we strive to ensure that our
          classrooms are not just spaces for instruction, but environments that
          inspire curiosity, foster understanding, and stimulate intellectual
          growth.
        </div>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            Advanced Learning Facilities for Academic Excellence
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            BGS World School is committed to providing an environment that
            fosters academic excellence through state-of-the-art facilities. The
            campus features modern classrooms equipped with smart boards and
            digital learning tools, enhancing the learning experience and
            enabling interactive teaching methods. The school’s well-stocked
            library offers a diverse collection of books, periodicals, and
            digital resources, ensuring that students have access to a broad
            range of knowledge. In addition, BGS World School has fully equipped
            science and computer laboratories, allowing students to engage in
            hands-on learning experiences. These labs enable students to apply
            theoretical concepts in a practical setting, deepening their
            understanding of core subjects. These advanced facilities are
            integral to creating an environment where students can excel
            academically and develop critical thinking skills, preparing them
            for future academic challenges and success in their careers.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?q=80&w=1473&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Comprehensive Sports and Extracurricular Resources
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                At BGS World School, the focus extends beyond academics to
                ensure a well-rounded development for students. The school
                offers extensive sports facilities, including a large
                playground, basketball courts, and a swimming pool, encouraging
                students to engage in various physical activities. These
                facilities not only promote physical fitness but also instill
                teamwork and leadership skills. The school also houses a
                well-equipped auditorium, where students participate in cultural
                events, assemblies, and performances, providing a platform for
                them to showcase their talents. Additionally, dedicated art and
                music rooms offer students the opportunity to explore and
                nurture their creative interests. By offering a wide range of
                extracurricular activities, BGS World School ensures that
                students develop holistically, balancing academic achievements
                with personal growth and creative expression.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Commitment to Student Safety and Well-being
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                BGS World School places a strong emphasis on the safety and
                well-being of its students, ensuring a secure and nurturing
                environment. The school is equipped with a modern medical
                infirmary, staffed by qualified healthcare professionals ready
                to address any health concerns that may arise. Security measures
                are stringent, with 24/7 surveillance through CCTV cameras and a
                secure entry system to protect students at all times. In
                addition, the school provides a spacious and hygienic cafeteria,
                offering nutritious meals and snacks that cater to the dietary
                needs of students. These facilities are designed not only to
                ensure the physical safety of students but also to promote their
                overall well-being, allowing them to focus on their studies and
                personal development in a supportive environment. BGS World
                School is committed to creating a safe, healthy space where
                students can thrive both academically and personally.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://plus.unsplash.com/premium_photo-1682284353484-4e16001c58eb?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
