import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Footer from "../components/Footer";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import HistoryIcon from "@mui/icons-material/History";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SchoolIcon from "@mui/icons-material/School";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import RadarIcon from "@mui/icons-material/Radar";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import ClassIcon from "@mui/icons-material/Class";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Explore from "./Explore";
import VisitUs from "../components/VisitUs";



export default function Dashboard() {
  const [transition, setTransition] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [newsTranslate, setNewsTranslate] = useState(0);
  const [newsCount, setNewsCount] = useState(1);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const ref = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(-200, -200);
    setTimeout(() => setTransition(true), 500);
    setTimeout(() => {
      gsap.to(".loader", { opacity: 0, duration: 0.1 }).then(() => {
        setIsLoading(false);

        setTimeout(() => {
          setInterval(() => {
            gsap
              .to(".newsDot", {
                opacity: 0,
                scale: 6,
                duration: 0.5,
              })
              .then(() => {
                gsap.to(".newsDot", {
                  opacity: 1,
                  scale: 1,
                  duration: 0,
                });
              });
          }, 1000);
          gsap.to(".hero, .video, .overlay", {
            opacity: 1,
            duration: 0.1,
          });
          let newsItems = gsap.utils.toArray(".icard");

          newsItems.forEach((item, i) => {
            let hover = gsap.to(item, {
              scale: 1.025,
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
              duration: 0.3,
              paused: true,
              ease: "power2.inOut",
            });
            item.addEventListener("mouseenter", () => {
              hover.play();
            });
            item.addEventListener("mouseleave", () => {
              hover.reverse();
            });
          });
        }, 100);
      });
    }, 500);
  }, []);

  function newsLeft() {
    if (newsCount > 0) {
      gsap.to(".newsSlider", {
        translateX: newsTranslate + 200,
        duration: 0.5,
      });
      setNewsTranslate(newsTranslate + 200);
      setNewsCount(newsCount - 1);
      dispatch({
        type: "NAV_COLOR",
        payload: { navColor: "#444", navOpacity: 1 },
      });
    }
  }

  function newsRight() {
    if (newsCount < 2) {
      gsap.to(".newsSlider", {
        translateX: newsTranslate - 200,
        duration: 0.5,
      });
      setNewsTranslate(newsTranslate - 200);
      setNewsCount(newsCount + 1);
      dispatch({
        type: "NAV_COLOR",
        payload: { navColor: "#444", navOpacity: 1 },
      });
    }
  }

  function getContent() {
    if (isLoading) {
      return (
        <Lottie
          className="loader"
          style={{ height: "0" }}
          animationData={loading}
          loop={true}
        />
      );
    } else {
      return (
        <div className="main">
          <Sidebar />
          <div className="overlay"></div>
          <div
            style={{
              height: "75px",
              width: "75px",
              borderRadius: "40px",
              backgroundColor: "black",
              position: "fixed",
              right: 20,
              zIndex: 4,
              bottom: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
            }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="takemeup"
          >
            <ArrowUpwardIcon style={{ color: "white", fontSize: "40px" }} />
          </div>
          <video
            src={bg}
            type="video/mp4"
            autoPlay
            loop
            muted
            id="video"
            className="video"
          />{" "}
          <div
            className="herotitle"
            style={{
              marginTop: "-65vh",
              marginBottom: "50vh",
              textAlign: "center",
              height: "200px",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "75px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              textShadow: "8px 8px 8px black",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            WELCOME TO BGS WORLD SCHOOL
          </div>
          <div className="hero" style={{}}>
            <div
              style={{
                fontSize: "55px",
                fontWeight: "bold",
                fontFamily: "Montserrat",              color:"#756bb1"

              }}
            >
              BGS WORLD SCHOOL
            </div>
            <br />
            <br />
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginBottom: "30px",
              }}
            >
              We develop PreK-12 students into unstoppable learners and leaders
              who are inspired to make an impact, determined to fulfill their
              potential, and driven to take control of their futures.
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontFamily: "Montserrat",
                textDecoration: "underline",
              }}
            >
              Learn more about the Maclay Advantage
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              For quick answers to our most commonly asked questions, visit our
              FAQ page.
            </div>
          </div>
          <div className="growth">
            <div
              style={{
                marginTop: "-650px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: "100px",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "500px",
                    width: "15%",
                    backgroundColor: "white",
                    zIndex: 3,
                  }}
                ></div>
                <div
                  style={{
                    height: "300px",
                    width: "70%",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => newsLeft()}
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "10px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      zIndex: 3,
                    }}
                  >
                    <ChevronLeftIcon
                      style={{ color: "white", fontSize: "40px" }}
                    />
                  </div>
                  <div
                    onClick={() => newsRight()}
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "10px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      zIndex: 3,
                    }}
                  >
                    <ChevronRightIcon
                      style={{ color: "white", fontSize: "40px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    height: "500px",
                    width: "15%",
                    backgroundColor: "white",
                    zIndex: 3,
                  }}
                ></div>
              </div>
              <div
                className="newsSlider"
                style={{
                  marginTop: "-252px",
                  height: "5px",
                  width: "100%",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "180px",
                  }}
                >
                  <div
                    className="newsDot"
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "15px",
                      marginTop: "65px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginTop: "-20px",
                      borderRadius: "15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}
                  >
                    1995
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "155px",
                      marginTop: "15px",
                      fontSize: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    SSLC Building was established at Our first building campus
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "180px",
                  }}
                >
                  <div
                    className="newsDot"
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "15px",
                      marginTop: "65px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginTop: "-20px",
                      borderRadius: "15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}
                  >
                    1996
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "155px",
                      marginTop: "15px",
                      fontSize: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    SSLC Building was established at Our first building campus
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "180px",
                  }}
                >
                  <div
                    className="newsDot"
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "15px",
                      marginTop: "65px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginTop: "-20px",
                      borderRadius: "15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}
                  >
                    1997
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "155px",
                      marginTop: "15px",
                      fontSize: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    SSLC Building was established at Our first building campus
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "180px",
                  }}
                >
                  <div
                    className="newsDot"
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "15px",
                      marginTop: "65px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginTop: "-20px",
                      borderRadius: "15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}
                  >
                    1998
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "155px",
                      marginTop: "15px",
                      fontSize: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    SSLC Building was established at Our first building campus
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "180px",
                  }}
                >
                  <div
                    className="newsDot"
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "15px",
                      marginTop: "65px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginTop: "-20px",
                      borderRadius: "15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}
                  >
                    1999
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "155px",
                      marginTop: "15px",
                      fontSize: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    SSLC Building was established at Our first building campus
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "180px",
                  }}
                >
                  <div
                    className="newsDot"
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "15px",
                      marginTop: "65px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      height: "25px",
                      width: "25px",
                      marginTop: "-20px",
                      borderRadius: "15px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}
                  >
                    2000
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "155px",
                      marginTop: "15px",
                      fontSize: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    SSLC Building was established at Our first building campus
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutabout2" style={{ marginTop: "-300px" }}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <div
                  style={{
                    fontSize: "50px",
                    fontFamily: "Montserrat",
                    textAlign: "left",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontWeight: "bold",
                  }}
                >
                  About Math
                </div>
                <br />
                <div
                  style={{
                    fontSize: "25px",
                    fontFamily: "Montserrat",
                    textAlign: "left",
                  }}
                >
                  Sri Adichunchanagiri Shikshana Trust (R.) was founded in the
                  year 1974 under the divine leadership of His Holiness
                  Byravaikya Sri Sri Sri Dr. Balagangadharanatha Maha
                  Swamiji.Following the ‘Guru-Shishya’ Parampara, his holiness
                  Byravaikya Jagadguru Sri Sri Sri Dr. Balagangadharanatha Maha
                  Swamiji, 71st in the holy lineage, who ascended the revered
                  seat in 1974. The benevolent activities of social service
                  gained an accelerated momentum since then.
                  <br /> Sri Adichunchanagiri Mahasamsthana Math, with its roots
                  firmly embedded in the folds of time (1500 Years), Space on
                  Rocky hill, 63 miles West of Bangalore, the Capital City of
                  Karnataka, has been working towards the betterment of human
                  life and society.
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "30px 0px 10px 0px",
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonTextColor
                          : themeStyle.primaryButtonTextColor,
                    }}
                    to={"/about"}
                  >
                    <Button
                      style={{
                        padding: "30px",
                        border:
                          "2px " + state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor + " solid",
                        borderRadius: "45px",
                        height: "30px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    >
                      Read More
                    </Button>
                  </Link>
                </div>
              </Grid>

              <Grid item xs={5}>
                <div className="slider">
                  <div className="slides-container">
                    <div className="slides-inner">
                      <div className="slide">
                        <img
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            margin: "10px",
                            height: "100%",
                          }}
                          src={
                            "https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                        />
                      </div>
                      <div className="slide">
                        <img
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            margin: "10px",
                            height: "100%",
                          }}
                          src={
                            "https://images.unsplash.com/photo-1523050854058-8df90110c9f1?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                        />
                      </div>
                      <div className="slide">
                        <img
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            margin: "10px",
                            height: "100%",
                          }}
                          src={
                            "https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="controls">
                    <Button
                      className="button-carousel"
                      id="prevButton"
                      style={{
                        border: "2px solid " + themeStyle.primaryColor,
                        borderRadius: "25px",
                        height: "30px",
                        fontSize: "15px",
                        color: themeStyle.primaryColor,
                        fontWeight: "bold",
                        padding: "2px 10px 0px 10px",
                      }}
                    >
                      Prev
                    </Button>

                    <Button
                      className="button-carousel"
                      id="nextButton"
                      style={{
                        marginLeft: "15px",
                        border: "2px solid " + themeStyle.primaryColor,
                        borderRadius: "25px",
                        height: "30px",
                        fontSize: "15px",
                        color: themeStyle.primaryColor,
                        fontWeight: "bold",
                        padding: "2px 10px 0px 10px",
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="news">
            <div
              style={{
                textAlign: "center",
                marginBottom: "50px",
                marginTop: "0px",
                fontSize: "55px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:"#756bb1"

              }}
            >
              NEWS AND UPDATES
            </div>

            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="icard">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1672691612619-29301d9aafac?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "15px",
                      position: "relative",
                      marginTop: "-160px",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "20px",
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Hardanger, Norway
                    </div>
                    <div
                      style={{
                        fontSize: "30px",
                        color: "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      New principal appointed, students demand holiday
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
                <div style={{ display: "flex" }}>
                  <div className="icard">
                    <img
                      src="https://images.unsplash.com/photo-1544605718-ae6280f9e49c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Norway"
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      Hardanger, Norway
                    </div>
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color: "black",
                        fontFamily: "Montserrat",
                        textAlign: "left",
                      }}
                    >
                      New principal appointed, students demand holiday
                    </div>
                  </div>
                  <div style={{ width: "50px" }}></div>
                  <div className="icard">
                    <img
                      src="https://images.unsplash.com/photo-1722343879466-7dcda68a48cc?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Norway"
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      Hardanger, Norway
                    </div>
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color: "black",
                        fontFamily: "Montserrat",
                        textAlign: "left",
                      }}
                    >
                      New principal appointed, students demand holiday
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div style={{ display: "flex" }}>
                  <div className="icard">
                    <img
                      src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Norway"
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      Hardanger, Norway
                    </div>
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color: "black",
                        fontFamily: "Montserrat",
                        textAlign: "left",
                      }}
                    >
                      New principal appointed, students demand holiday
                    </div>
                  </div>
                  <div style={{ width: "50px" }}></div>
                  <div className="icard">
                    <img
                      src="https://images.unsplash.com/photo-1722365872243-39372c5b7cfe?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Norway"
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      Hardanger, Norway
                    </div>
                    <div
                      style={{
                        padding: "0px 10px 0px 10px",
                        fontSize: "20px",
                        color: "black",
                        fontFamily: "Montserrat",
                        textAlign: "left",
                      }}
                    >
                      New principal appointed, students demand holiday
                    </div>
                  </div>
                </div>
                <br />
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1719937051124-91c677bc58fc?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "15px",
                      position: "relative",
                      marginTop: "-160px",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "20px",
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Hardanger, Norway
                    </div>
                    <div
                      style={{
                        fontSize: "30px",
                        color: "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      New principal appointed, students demand holiday
                    </div>
                  </div>
                </div>
              </Grid>{" "}
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "50px 0px 10px 0px",
              }}
            >
              <Button
                onClick={()=>{
                  navigate("/news_and_events");
                }}
                style={{
                  padding: "40px",
                  border:
                    "2px " + state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor + " solid",
                  borderRadius: "45px",
                  height: "50px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              >
                MORE CAMPUS NEWS
              </Button>
            </div>
          </div>
          <div
            className="aboutabout3"
            style={{
              paddingBottom: "50px",
              backgroundColor:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            <div
              style={{
                marginTop: "-200px",
                fontSize: "55px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              Setting Benchmark in Quality Education Since 1959
            </div>
            <br />
            <br />
            <Grid container spacing={2.5} style={{ width: "80%" }}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard" style={{ backgroundColor: "white" }}>
                  <CountUp
                    end={100}
                    duration={100}
                    onEnd={() => {}}
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "75px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginTop: "-30px",
                      paddingBottom: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Educators
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard" style={{ backgroundColor: "white" }}>
                  <CountUp
                    end={10}
                    duration={100}
                    onEnd={() => {}}
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "75px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginTop: "-30px",
                      paddingBottom: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Campuses
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard" style={{ backgroundColor: "white" }}>
                  <CountUp
                    end={1200}
                    duration={100}
                    onEnd={() => {}}
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "75px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginTop: "-30px",
                      paddingBottom: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Students
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard" style={{ backgroundColor: "white" }}>
                  <CountUp
                    end={5240}
                    duration={100}
                    onEnd={() => {}}
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "75px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginTop: "-30px",
                      paddingBottom: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                    }}
                  >
                    New admissions
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="news">
            <div
              style={{
                textAlign: "center",
                marginBottom: "50px",
                marginTop: "50px",
                fontSize: "55px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              A Glimpse of the Campus
            </div>
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              container
              spacing={2.5}
              style={{ justifyContent: "center", paddingBottom: "100px" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <div className="icard">
                  <img
                    src="https://images.unsplash.com/photo-1722399442178-dd5a0faa2d58?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Norway"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Hardanger, Norway
                  </div>
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      fontSize: "20px",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Nursery - Tata Silk Farm
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Explore />
          <VisitUs />
          <Footer />
        </div>
      );
    }
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: transition ? 1 : 0,
      }}
    >
      {getContent()}
    </div>
  );
}
