import { themeStyle, themeStyleDark } from "../components/Components";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export default function Slider() {
  const state = useSelector((state) => state);

  return (
    <div className="aboutabout2" style={{ marginTop: "-300px" }}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          <div
            style={{
              fontSize: "50px",
              fontFamily: "Montserrat",
              textAlign: "left",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
            }}
          >
            About BGS World School
          </div>
          <br />
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            At BGS World School, our classrooms are thoughtfully designed to
            provide an optimal learning environment for our students. Spacious,
            well-lit, and equipped with modern teaching aids, these classrooms
            offer a conducive space for intellectual engagement and interaction.
            Whether it’s a lecture for our B.Com program, a group discussion in
            a BBA course, or a seminar for M.Com students, our classrooms can
            adapt to varied teaching methods and learning preferences. Beyond
            their physical features, our classrooms are where the
            transformational journey of learning takes place.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "30px 0px 10px 0px",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryButtonTextColor
                    : themeStyle.primaryButtonTextColor,
              }}
              to={"/about"}
            >
              <Button
                style={{
                  padding: "30px",
                  border:
                    "2px " + state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor + " solid",
                  borderRadius: "45px",
                  height: "30px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              >
                Read More
              </Button>
            </Link>
          </div>
        </Grid>

        <Grid item xs={5}>
          <div className="slider">
            <div className="slides-container">
              <div className="slides-inner">
                <div className="slide">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                      height: "100%",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </div>
                <div className="slide">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                      height: "100%",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1523050854058-8df90110c9f1?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </div>
                <div className="slide">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                      height: "100%",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="controls">
              <Button
                className="button-carousel"
                id="prevButton"
                style={{
                  border: "2px solid " + themeStyle.primaryColor,
                  borderRadius: "25px",
                  height: "30px",
                  fontSize: "15px",
                  color: themeStyle.primaryColor,
                  fontWeight: "bold",
                  padding: "2px 10px 0px 10px",
                }}
              >
                Prev
              </Button>

              <Button
                className="button-carousel"
                id="nextButton"
                style={{
                  marginLeft: "15px",
                  border: "2px solid " + themeStyle.primaryColor,
                  borderRadius: "25px",
                  height: "30px",
                  fontSize: "15px",
                  color: themeStyle.primaryColor,
                  fontWeight: "bold",
                  padding: "2px 10px 0px 10px",
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
