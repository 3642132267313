import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import axios from "axios";
import { API_URL } from "../actions/auth";
import { useNavigate } from "react-router-dom";

export default function Sports() {
  const state = useSelector((state) => state);
  const [sports, setSports] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(API_URL + "sports")
      .then((res) => {
        dispatch({
          type: "NAV_COLOR",
          payload: { navColor: "#444", navOpacity: 1 },
        });
        setSports(res.data);
      })
      .catch((err) => {});
  }, []);
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1516487200032-8532cb603261?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Sports{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop: "100px" }}
        >
          <b>
            The inaugural class of 2024 juniors selected The Dragons as the name
            of our NCSSM-Morganton athletics teams in 2022, and we began
            competing in interscholastic competition. NCSSM-Morganton fields 15
            varsity teams competing in the Western Highlands Conference of the
            North Carolina High School Athletic Association.
          </b>
          <br />
          <br />
          In addition to varsity sports on campus, a popular intramural program
          gives students a great physical outlet to balance their academic work.
          Intramural sports include basketball, dodgeball, flag football,
          fencing, soccer, volleyball, and ultimate frisbee, with more coming
          soon. We have a small exercise room in the residence hall, and our
          students have access to athletic facilities on the neighboring
          campuses of WPCC and NC School for the Deaf, and outdoor trails help
          you stay healthy and burn off stress. Planned for construction is the
          UNC Health Blue Ridge Student Wellness and Activities Center, which
          will stand across from Goodwin Hall and offer a basketball gymnasium
          and workout facilities as well as host Counseling and the Student
          Health Clinic.
        </div>

        <div
          className="aboutabout"
          style={{
            fontSize: "22px",
            marginTop: "-50px",
            marginBottom: "180px",
          }}
        >
          <Grid container spacing={5} style={{ width: "80%" }}>
            {sports.map((newa, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{ height: "35vh" }}
              >
                <div
                  onClick={() => {
                    navigate("/news", { state: newa });
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    objectFit: "length",
                    margin: "10px",
                    height: "100%",
                    color: "white",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    background:
                      "linear-gradient(0deg, rgba(0,0,0,0.9), rgba(2, 77, 194, 0.0), rgba(2, 77, 194, 0.0)), url('" +
                      API_URL +
                      "/media/" +
                      newa.image +
                      "')",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      textAlign: "left",
                      bottom: -10,
                    }}
                  >
                    <div
                      style={{
                        fontSize: "17px",
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      {newa.title}{" "}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
