import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect, useNavigation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import Explore from "../pages/Explore";
import axios from "axios";
import { API_URL } from "../actions/auth";

export default function News() {
  const state = useSelector((state) => state);
  const [news, setNews] = useState([
    { image: "", title: "", subtitle: "" },
    { image: "", title: "", subtitle: "" },
    { image: "", title: "", subtitle: "" },
    { image: "", title: "", subtitle: "" },
  ]);
  const dispatch = useDispatch();

  const [newas, setNewas] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(API_URL + "news_and_events")
      .then((res) => {
        dispatch({
          type: "NAV_COLOR",
          payload: { navColor: "#444", navOpacity: 1 },
        });
        setNews(res.data);
        var a = Array.from(res.data);
        a.splice(1, 3);
        setNewas(a);
      })
      .catch((err) => {});
  }, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          style={{
            paddingTop: "300px",
            paddingBottom: "100px",
            paddingLeft: "50px",
            paddingRight: "50px",
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.5), rgba(2, 77, 194, 0.5)), url('https://images.unsplash.com/photo-1709377060397-14c021810ebc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                paddingBottom: "100px",
                marginBottom: "50px",
                fontSize: "55px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryButtonTextColor
                    : themeStyle.primaryButtonTextColor,
              }}
            >
              NEWS AND UPDATES
            </div>
            <Grid
              container
              spacing={0}
              style={{
                height: "60vh",
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={7}
                xl={7}
                style={{ width: "100%", height: "60vh" }}
              >
                <div
                  onClick={() => {
                    navigate("/news", { state: news[0] });
                  }}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    alt="Norway"
                    style={{
                      height: "60vh",
                      width: "100%",
                      objectFit: "cover",
                      background:
                        "linear-gradient(0deg, rgba(0,0,0,0.9), rgba(2, 77, 194, 0.0), rgba(2, 77, 194, 0.0)), url('" +
                        API_URL +
                        "/media/" +
                        news[0]["image"] +
                        "')",
                    }}
                  />
                  <div
                    style={{
                      borderTopRightRadius: "50px",
                      width: "40%",
                      padding: "20px",
                      position: "absolute",
                      textAlign: "left",
                      bottom: -120,
                    }}
                  >
                    <div
                      style={{
                        fontSize: "25px",
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      {news[0]["title"]}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={5}
                xl={5}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                {newas.map((newa, index) => (
                  <div
                    onClick={() => {
                      navigate("/news", { state: newa });
                    }}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      padding: "0px",
                      borderRadius: "10px",
                      height: "100%",
                      width: "100%",
                      marginLeft: "45px",
                      marginBottom: newas.length == index + 1 ? "0px" : "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        height: "100%",
                        backgroundSize: "contain",
                        background:
                          "url('" + API_URL + "/media/" + newa["image"] + "')",
                      }}
                    ></div>
                    <div style={{ width: "50%" }}>
                      <div
                        style={{
                          padding: "0px 10px 0px 20px",
                          fontSize: "15px",
                          color: "yellow",
                          fontFamily: "Montserrat",
                          textAlign: "left",
                          fontWeight: 600,
                        }}
                      >
                        {"NEWS"}
                      </div>
                      <div
                        style={{
                          padding: "0px 10px 0px 20px",
                          fontSize: "20px",
                          color: "white",
                          fontFamily: "Montserrat",
                          textAlign: "left",
                          fontWeight: 600,
                        }}
                      >
                        {newa["title"].substring(0, 100)}
                      </div>
                    </div>
                  </div>
                ))}
              </Grid>
            </Grid>
          </div>
        </div>

        <div
          className="aboutabout"
          style={{
            fontSize: "22px",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          The BGS World School campus is a state-of-the-art educational hub
          equipped to deliver top-notch B.Com, BBA, and M.Com programs. It
          features advanced learning facilities, sports and cultural amenities,
          and a resource-rich library. The green and serene environment fosters
          academic focus and promotes a healthy, balanced student life.
        </div>

        <div
          className="aboutabout"
          style={{
            fontSize: "22px",
            marginTop: "-50px",
            marginBottom: "180px",
          }}
        >
          <Grid container spacing={5} style={{ width: "80%" }}>
            {news.map((newa, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{ height: "35vh" }}
              >
                <div
                  onClick={() => {
                    navigate("/news", { state: newa });
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    objectFit: "cover",
                    margin: "10px",
                    height: "100%",
                    color: "white",
                    textAlign: "left",
                  display:"flex",
                  flexDirection:"column",
                  justifyContent:"flex-end",
                    fontWeight: "bold",
                    backgroundSize:"contain",
                    backgroundRepeat:"no-repeat",
                    background:
                      "linear-gradient(0deg, rgba(0,0,0,0.9), rgba(2, 77, 194, 0.0), rgba(2, 77, 194, 0.0)), url('" +
                      API_URL +
                      "/media/" +
                      newa.image +
                      "')",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      textAlign: "left",
                      bottom:-10,
                    }}
                  >
                    <div
                      style={{
                        fontSize: "17px",
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      {newa.title}{" "}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>

        <Benchmark />

        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
