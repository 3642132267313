import React from "react";
import { themeStyle, themeStyleDark } from "./Components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer(props) {
  const state = useSelector((state) => state);

  return (
    <div
    className="footer"
      style={{
        backgroundColor: "black",
        padding: "50px",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.primaryColor
            : themeStyle.primaryColor,
      }}
    >
      <Grid container spacing={5}>
        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            BGS World School
          </div>
          <div
            style={{
              fontSize: "20px",
              fontFamily: "Montserrat",
              color: "white",
              marginBottom: "50px",
            }}
          >
            Shop Nos.1CB, 1BA, 1BB, 1BC & 1CA, Ground ; NAVNEET TOWER ROAD ·
            Phone number : 731-4974178
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            BGS Emergency Information
          </div>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            &nbsp;{" "}
          </div>
          <div
            style={{
              fontSize: "20px",
              fontFamily: "Montserrat",
              color: "white",
              marginBottom: "50px",
            }}
          >
            NAVNEET TOWER ROAD · Phone number : 731-4974178 ; MALHAR MEGA MALL ·
            Phone number : 7314290036 ; PRIME HOUSE, VIJAY
          </div>

        </Grid>
        <Grid
          item
          md={1.5}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {" "}
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Alumni
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Educators
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Current Parents
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Employees
          </div>
        </Grid>
        <Grid
          item
          md={1.5}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {" "}
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            NCSSM Connect
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Careers
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            School Fees
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Contact
          </div>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Button
            style={{
              border: "2px white solid",
              borderRadius: "25px",
              width: "100%",
              height: "50px",
              fontSize: "20px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            JOIN OUR MAILING LIST
          </Button>
          <br />
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                border: "2px white solid",
                borderRadius: "40px",
                width: "70px",
                height: "70px",
                fontSize: "20px",
                color: "white",
              }}
            >
              <XIcon style={{ color: "white", fontSize: "40px" }} />
            </Button>
            <Button
              style={{
                border: "2px white solid",
                borderRadius: "40px",
                width: "70px",
                height: "70px",
                fontSize: "20px",
                color: "white",
              }}
            >
              <LinkedInIcon style={{ color: "white", fontSize: "40px" }} />
            </Button>
            <Button
              style={{
                border: "2px white solid",
                borderRadius: "40px",
                width: "70px",
                height: "70px",
                fontSize: "20px",
                color: "white",
              }}
            >
              <EmailIcon style={{ color: "white", fontSize: "40px" }} />
            </Button>
            <Button
              style={{
                border: "2px white solid",
                borderRadius: "40px",
                width: "70px",
                height: "70px",
                fontSize: "20px",
                color: "white",
              }}
            >
              <FacebookIcon style={{ color: "white", fontSize: "40px" }} />
            </Button>
            <Button
              style={{
                border: "2px white solid",
                borderRadius: "40px",
                width: "70px",
                height: "70px",
                fontSize: "20px",
                color: "white",
              }}
            >
              <InstagramIcon style={{ color: "white", fontSize: "40px" }} />
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
