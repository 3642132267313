import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import Slider from "./Slider";

export default function Leadership() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1598015132635-131afe3ba07f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Leadership{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          ‘Time and tide’ waits for none. Rapid globalisation and
          hypercompetitive markets make it imperative to align management
          education, to effectively meet the dynamics of corporate challenges.
          With the growing awareness about the complexity of modern business;
          the demand for management and commerce graduates, with a high level of
          domain knowledge and core competencies is fast increasing. BGS
          Institute of Management Studies alive to this emerging trend provides
          market-oriented knowledge and skills to the students.
          <br /> <br />
          Right from its inception in 2007, the Institute has continuously
          evolved in many innovative ways of learning and experience sharing,
          with a futuristic vision and mission-oriented goals. BGS World School has
          established an excellent learning environment with a moral and
          spiritual atmosphere. The institute offers industry-focused training
          and development programmes to all students to meet competitive world
          expectations. This has been made possible by highly qualified and
          experienced faculty and state-of-the-art infrastructure.
          <br />
          <br /> Our education also emphasizes on all-round personality
          development and interactive practical learning through close
          interfacing with lead companies to groom our students as future
          corporate leaders. BGS World School moves ahead into the challenging future with
          the mission to carry forward its goals and set new milestones in the
          era of world-class modern management education. I welcome all the
          aspirants to BGS World School and wish them a happy and prosperous future ahead.
        </div>
        <Slider/>
        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
