import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import Slider from "./Slider";



export default function BoysHostel() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Boys Hostel at BGS World school{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            {" "}
            At BGS World School, we are proud to provide our male students with
            comfortable, secure, and well-maintained hostel facilities. Our
            boys’ hostel is designed to foster a community environment,
            promoting both academic and personal growth. The rooms are designed
            for comfort, with provisions for both single and shared
            accommodation. The hostel is equipped with all necessary amenities,
            including study areas, recreational facilities, and Wi-Fi
            connectivity, to ensure a conducive living and learning environment.
          </b>
          <br />
          <br />
          In addition to the physical facilities, we place great emphasis on the
          overall wellbeing of our hostel residents. The hostel administration
          is always available to assist students and address their concerns
          promptly. Regular safety and security measures are in place to ensure
          the wellbeing of the residents. We also promote various activities
          aimed at community building and recreational engagement to provide a
          holistic hostel life. BGS World School’s boys’ hostel is not just a place to
          stay, but a space that contributes significantly to our students’
          overall college experience.
        </div>
        <Slider/>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            Comfortable and Secure Living Spaces
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            The boys' hostel at BGS World School offers a safe, comfortable, and
            nurturing environment where students can focus on their academic and
            personal growth. The rooms are spacious and well-ventilated, with
            modern furnishings that ensure a cozy and home-like atmosphere. Each
            room is equipped with individual study desks, ample storage space,
            and comfortable beds to cater to the needs of young scholars. The
            hostel provides 24/7 security with CCTV surveillance, secure entry
            points, and regular patrols to ensure the safety of all residents.
            Additionally, a dedicated hostel warden is always available to
            address the students' concerns, ensuring a peaceful and supportive
            living environment.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Holistic Development and Recreation
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                BGS World School's boys' hostel emphasizes the holistic
                development of its residents, offering a balanced mix of
                academics, extracurricular activities, and recreation. The
                hostel has designated study areas for uninterrupted learning and
                access to resources such as libraries and computer labs. To
                promote physical fitness and well-being, the hostel features
                sports facilities, including a gym, basketball court, and a
                playground, where students can engage in various activities.
                Regularly organized events, such as movie nights, talent shows,
                and cultural programs, provide opportunities for students to
                relax and bond with their peers, fostering a sense of community
                and belonging among residents.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Healthy and Nutritious Dining
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Ensuring the well-being of our students is a top priority at the
                BGS World School boys' hostel, and this extends to providing
                nutritious meals that support their growth and energy needs. The
                hostel's dining hall offers a variety of balanced meals,
                prepared under strict hygiene standards by experienced chefs.
                The menu is thoughtfully designed to include a wide range of
                dishes that cater to different tastes and dietary requirements,
                ensuring that students enjoy a healthy and varied diet. Regular
                feedback from students is encouraged to continuously improve the
                dining experience. The dining area is also a social hub where
                students come together to share meals, fostering friendships and
                a sense of camaraderie.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
