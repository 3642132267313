import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import Explore from "../pages/Explore";
import axios from "axios";
import { API_URL } from "../actions/auth";

export default function Gallery() {
  const state = useSelector((state) => state);
  const [gallery, setGallery] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(API_URL + "gallery")
      .then((res) => {
        dispatch({
          type: "NAV_COLOR",
          payload: { navColor: "#444", navOpacity: 1 },
        });
        setGallery(res.data);
      })
      .catch((err) => {});
  }, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1523698120758-030a38a90d16?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Gallery
          </div>
        </div>
        <div className="aboutabout" style={{ fontSize: "22px" }}>
          The BGS World School campus is a state-of-the-art educational hub
          equipped to deliver top-notch B.Com, BBA, and M.Com programs. It
          features advanced learning facilities, sports and cultural amenities,
          and a resource-rich library. The green and serene environment fosters
          academic focus and promotes a healthy, balanced student life.
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "180px" }}
        >
          <Grid container spacing={2.5} style={{ width: "80%" }}>
            {gallery.map((gal, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    margin: "10px",
                    height: "100%",
                  }}
                  src={API_URL + "/media/" + gal.image}
                />
                <div>{gal.title}</div>
              </Grid>
            ))}
          </Grid>
        </div>

        <Explore />
        <Benchmark />

        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
