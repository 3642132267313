export const themeStyleDark = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#4178ba",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#4178ba",
  primaryButtonTextColor: "white",
  secondaryButtonColor: "#000080",
};

export const themeStyle = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#4178ba",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#4178ba",
  primaryButtonTextColor: "black",
  secondaryButtonColor: "#000080",
};
