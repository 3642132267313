import React, { Fragment, useState, useEffect, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MailIcon from "@mui/icons-material/Mail";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SchoolIcon from "@mui/icons-material/School";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import { themeStyle, themeStyleDark } from "./Components";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import PersonIcon from "@mui/icons-material/Person";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HomeIcon from "@mui/icons-material/Home";
import Select from "@mui/material/Select";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ScrollTop from "../pages/ScrollTop";
import Snackbar from "@mui/material/Snackbar";

gsap.registerPlugin(ScrollTrigger);

const drawerWidth = 310;

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch({
      type: "NAV_COLOR",
      payload: { navColor: "#444", navOpacity: 1 },
    });
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch({
      type: "NAV_COLOR",
      payload: { navColor: "#444", navOpacity: 1 },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const [about, setAbout] = useState("");
  const [facility, setFacility] = useState("");
  const [admission, setAdmission] = useState("");
  const [studentLife, setStudentLife] = useState("");

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  useEffect(() => {
    gsap.to(".navbar", {
      backgroundColor: "#444",
      duration: 0.5,
      scrollTrigger: {
        start: 50,
        trigger: ".hero",
        toggleActions: "play none none reverse",
      },
    });

    gsap.to(".navtop", {
      backgroundColor: "#444",
      duration: 0.5,
      opacity: 1,
      scrollTrigger: {
        start: 50,
        trigger: ".hero",
        toggleActions: "play none none reverse",
      },
    });

    gsap.to(".takemeup", {
      opacity: 1,
      duration: 0.5,
      scrollTrigger: {
        start: 50,
        trigger: ".hero",
        toggleActions: "play none none reverse",
      },
    });

    let menuItems = gsap.utils.toArray(".sub-nav");
    let menuItems2 = gsap.utils.toArray(".nav-item");

    menuItems.forEach((item, i) => {
      let hover = gsap.to(item, {
        scale: 1.15,
        backgroundColor: themeStyle.primaryColor,
        color:
          state.auth.theme == "dark"
            ? themeStyleDark.primaryButtonTextColor
            : themeStyle.primaryButtonTextColor,
        duration: 0.3,
        paused: true,
        ease: "power2.inOut",
      });
      item.addEventListener("mouseenter", () => {
        hover.play();
      });
      item.addEventListener("mouseleave", () => {
        hover.reverse();
      });
    });

    menuItems2.forEach((item, i) => {
      let hover = gsap.to(item, {
        backgroundColor: themeStyle.primaryColor,
        color:
          state.auth.theme == "dark"
            ? themeStyleDark.primaryButtonTextColor
            : themeStyle.primaryButtonTextColor,
        duration: 0.3,
        paused: true,
        ease: "power2.inOut",
      });
      item.addEventListener("mouseenter", () => {
        hover.play();
      });
      item.addEventListener("mouseleave", () => {
        hover.reverse();
      });
    });

    try {
      var slideDelay = 5;
      var slideDuration = 1;
      var wrap = true;

      var slides = document.querySelectorAll(".slide");
      var prevButton = document.querySelector("#prevButton");
      var nextButton = document.querySelector("#nextButton");
      var progressWrap = gsap.utils.wrap(0, 1);

      var numSlides = slides.length;

      gsap.set(slides, {
        backgroundColor: "white",
        xPercent: (i) => i * 100,
      });

      var wrapX = gsap.utils.wrap(-100, (numSlides - 1) * 100);
      var timer = gsap.delayedCall(slideDelay, autoPlay);

      var animation = gsap.to(slides, {
        xPercent: "+=" + numSlides * 100,
        duration: 1,
        ease: "none",
        paused: true,
        repeat: -1,
        modifiers: {
          xPercent: wrapX,
        },
        delay: 5,
      });

      var proxy = document.createElement("div");
      var slideAnimation = gsap.to({}, {});
      var slideWidth = 0;
      var wrapWidth = 0;
      resize();

      window.addEventListener("resize", resize);

      prevButton.addEventListener("click", function () {
        animateSlides(1);
      });

      nextButton.addEventListener("click", function () {
        animateSlides(-1);
      });

      function animateSlides(direction) {
        timer.restart(true);
        slideAnimation.kill();
        var x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);

        slideAnimation = gsap.to(proxy, {
          x: x,
          duration: slideDuration,
          onUpdate: updateProgress,
        });
      }

      function autoPlay() {
        animateSlides(-1);
      }

      function updateProgress() {
        animation.progress(
          progressWrap(gsap.getProperty(proxy, "x") / wrapWidth)
        );
      }

      function snapX(value) {
        let snapped = gsap.utils.snap(slideWidth, value);
        return wrap
          ? snapped
          : gsap.utils.clamp(-slideWidth * (numSlides - 1), 0, snapped);
      }

      function resize() {
        var norm = gsap.getProperty(proxy, "x") / wrapWidth || 0;

        slideWidth = slides[0].offsetWidth;
        wrapWidth = slideWidth * numSlides;

        gsap.set(proxy, {
          x: norm * wrapWidth,
        });

        animateSlides(0);
        slideAnimation.progress(1);
      }
    } catch (error) {}
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ScrollTop />
      <Snackbar
        open={state.auth.alert != null ? true : false}
        autoHideDuration={6000}
        onClose={() => dispatch({ type: "REMOVE_ALERT" })}
        message={state.auth.alert == null ? "" : state.auth.alert.message}
        action={
          <div
            onClick={() => dispatch({ type: "REMOVE_ALERT" })}
            style={{ padding: "2px", cursor: "pointer" }}
          >
            <CloseIcon />
          </div>
        }
      />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{
          bgcolor: "transparent",
        }}
        style={{
          backdropFilter: "blur(4px)",
        }}
      >
        <div
          className="navtop"
          style={{
            paddingTop: "20px",
            backgroundColor: state.auth.navColors.navColor,
            width: "100%",
            display: "flex",
            opacity:
              window.location.pathname == "/"
                ? state.auth.navColors.navOpacity
                : 1,
            zIndex: 5,
            display:window.location.pathname == "/"
            ? "none"
            : "flex",
          }}
        >
          <div
            style={{
              width: "20%",
              backgroundColor: "#005a32",
              height: "8px",
            }}
          ></div>
          <div
            style={{
              width: "20%",
              backgroundColor: "#084594",
              height: "8px",
            }}
          ></div>
          <div
            style={{
              width: "20%",
              backgroundColor: "#d7301f",
              height: "8px",
            }}
          ></div>
          <div
            style={{
              width: "20%",
              backgroundColor: "#4a1486",
              height: "8px",
            }}
          ></div>
          <div
            style={{
              width: "20%",
              backgroundColor: "#feb24c",
              height: "8px",
            }}
          ></div>
        </div>
        <div
          className="navbar"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            padding: "10px 25px 10px 25px",
            width: "100%",
            backgroundColor: state.auth.navColors.navColor,
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
            to={"/"}
          >
            <h1
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              BGS World School
            </h1>
          </Link>
          <div>
            {state.auth.isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonTextColor
                          : themeStyle.primaryButtonTextColor,
                    }}
                    to={"/gallery"}
                  >
                    <div className="sub-nav">GALLERY</div>
                  </Link>
                  <div
                    className="sub-nav"
                    onClick={() => {
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "transparent", navOpacity: 0 },
                      });
                      navigate("/news_and_events");
                    }}
                  >
                    NEWS & EVENTS
                  </div>
                  <div
                    className="sub-nav"
                    onClick={() => {
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "transparent", navOpacity: 0 },
                      });
                      navigate("/sports");
                    }}
                  >
                    SPORTS
                  </div>
                  <div
                    className="sub-nav"
                    onClick={() => {
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "transparent", navOpacity: 0 },
                      });
                      navigate("/alumni");
                    }}
                  >
                    ALUMNI
                  </div>
                  <div
                    className="sub-nav"
                    onClick={() => {
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "transparent", navOpacity: 0 },
                      });
                      navigate("/contact");
                    }}
                  >
                    CONTACT US
                  </div>
                  <div
                    className="sub-nav"
                    onClick={() => {
                      dispatch({
                        type: "NAV_COLOR",
                        payload: { navColor: "transparent", navOpacity: 0 },
                      });
                      navigate("/campus");
                    }}
                  >
                    CAMPUS
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonTextColor
                          : themeStyle.primaryButtonTextColor,
                    }}
                    to={"/"}
                  >
                    <div className="nav-item">
                      <HomeIcon
                        className="nav-icon"
                        style={{
                          fontSize: 35,
                        }}
                      />
                      <div
                        style={{
                          marginLeft: "10px",
                          fontFamily: "Montserrat",
                          fontSize: "25px",
                        }}
                      >
                        Home
                      </div>
                    </div>
                  </Link>
                  <div className="nav-item" style={{ marginRight: "-5px" }}>
                    <SchoolIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <Select
                      displayEmpty
                      sx={{
                        padding: "0px",
                        height: "20px",
                        color: "white",
                        fontSize: "25px",
                        borderRadius: "50px",
                        borderColor: "blue",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover": {
                          "&& fieldset": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      value={about}
                      onChange={(e) => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "transparent", navOpacity: 0 },
                        });
                        navigate(e.target.value);
                      }}
                    >
                      <MenuItem
                        disabled
                        value=""
                        onClick={() => {}}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          display: "none",
                        }}
                      >
                        About
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/about"}>
                        About Math
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value="/about_school"
                        onClick={() => {
                          dispatch({
                            type: "NAV_COLOR",
                            payload: { navColor: "transparent", navOpacity: 0 },
                          });
                          navigate("/about_school");
                        }}
                        style={{ fontFamily: "Montserrat", fontWeight: "600" }}
                      >
                        About School
                      </MenuItem>

                      <MenuItem className="nav-menu-item" value={"/cao"}>
                        CAO
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/principal"}>
                        Principal
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/campus"}>
                        Campus
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/mission"}>
                        Mission & Vision
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/leadership"}>
                        Our Leadership
                      </MenuItem>
                    </Select>
                  </div>

                  <div className="nav-item" style={{ marginRight: "-5px" }}>
                    <PersonIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <Select
                      displayEmpty
                      sx={{
                        padding: "0px",
                        height: "20px",
                        color: "white",
                        fontSize: "25px",

                        borderRadius: "50px",
                        borderColor: "blue",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover": {
                          "&& fieldset": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      value={studentLife}
                      onChange={(e) => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "transparent", navOpacity: 0 },
                        });
                        navigate(e.target.value);
                      }}
                    >
                      <MenuItem
                        disabled
                        className="nav-menu-item"
                        value=""
                        onClick={() => {}}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          display: "none",
                        }}
                      >
                        Student Life
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value="/student_life"
                        onClick={() => {
                          dispatch({
                            type: "NAV_COLOR",
                            payload: { navColor: "transparent", navOpacity: 0 },
                          });
                          navigate("/student_life");
                        }}
                      >
                        Overview
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/gallery"}>
                        Gallery
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/campus"}>
                        Campus Experience
                      </MenuItem>
                    </Select>
                  </div>
                  <div className="nav-item" style={{ marginRight: "-5px" }}>
                    <AddTaskIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <Select
                      displayEmpty
                      sx={{
                        padding: "0px",
                        height: "20px",
                        color: "white",
                        borderRadius: "50px",
                        fontSize: "25px",

                        borderColor: "blue",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover": {
                          "&& fieldset": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      value={admission}
                      onChange={(e) => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "transparent", navOpacity: 0 },
                        });
                        navigate(e.target.value);
                      }}
                    >
                      <MenuItem
                        disabled
                        className="nav-menu-item"
                        value=""
                        onClick={() => {}}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          display: "none",
                        }}
                      >
                        Admissions
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value="/overview"
                        onClick={() => {
                          dispatch({
                            type: "NAV_COLOR",
                            payload: { navColor: "transparent", navOpacity: 0 },
                          });
                          navigate("/overview");
                        }}
                      >
                        Overview
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value={"/how_to_apply"}
                      >
                        How to Apply
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/apply"}>
                        Apply Now
                      </MenuItem>
                    </Select>
                  </div>

                  <div className="nav-item" style={{ marginRight: "0px" }}>
                    <SportsBasketballIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <Select
                      displayEmpty
                      inputProps={{
                        MenuProps: {
                          classes: {
                            paper: { backgroundColor: "red" },
                          },
                        },
                      }}
                      sx={{
                        padding: "0px",
                        height: "20px",
                        fontSize: "25px",

                        color: "white",
                        borderRadius: "50px",
                        borderColor: "blue",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover": {
                          "&& fieldset": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      value={facility}
                      onChange={(e) => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "transparent", navOpacity: 0 },
                        });
                        navigate(e.target.value);
                      }}
                    >
                      <MenuItem
                        disabled
                        className="nav-menu-item"
                        value=""
                        onClick={() => {}}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          display: "none",
                        }}
                      >
                        Facilities
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value="/facilities"
                        onClick={() => {
                          dispatch({
                            type: "NAV_COLOR",
                            payload: { navColor: "transparent", navOpacity: 0 },
                          });
                          navigate("/facilities");
                        }}
                      >
                        Overview
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/classroom"}>
                        Classroom
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value={"/boys_hostel"}
                      >
                        Boys Hostel
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value={"/girls_hostel"}
                      >
                        Girls Hostel
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/library"}>
                        Library
                      </MenuItem>
                      <MenuItem
                        className="nav-menu-item"
                        value={"/computer_labs"}
                      >
                        Computer Labs
                      </MenuItem>

                      <MenuItem
                        className="nav-menu-item"
                        value={"/transportation"}
                      >
                        Transportation
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"/playground"}>
                        Playground
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </AppBar>

      {state.auth.isMobile ? (
        <Drawer variant="temporary" anchor="top" open={open} style={{}}>
          <DrawerHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link
              style={{
                textDecoration: "none",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
              to={"/"}
            >
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                BGS World School
              </h1>
            </Link>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem
              style={{ cursor: "pointer" }}
              key={"1"}
              onClick={() => {
                dispatch({
                  type: "NAV_COLOR",
                  payload: { navColor: "transparent", navOpacity: 0 },
                });
                navigate("/gallery");
              }}
            >
              <ListItemIcon>
                <ArrowRightIcon
                  style={{
                    fontSize: 35,
                    marginLeft: "0px -100px 0px 35px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </ListItemIcon>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                Gallery
              </div>
            </ListItem>
            <ListItem
              style={{ cursor: "pointer" }}
              key={"2"}
              onClick={() => {
                dispatch({
                  type: "NAV_COLOR",
                  payload: { navColor: "transparent", navOpacity: 0 },
                });
                navigate("/news_and_events");
              }}
            >
              <ListItemIcon>
                <ArrowRightIcon
                  style={{
                    fontSize: 35,
                    marginLeft: "0px -100px 0px 35px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </ListItemIcon>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                News and Events
              </div>
            </ListItem>
            <ListItem
              style={{ cursor: "pointer" }}
              key={"3"}
              onClick={() => {
                dispatch({
                  type: "NAV_COLOR",
                  payload: { navColor: "transparent", navOpacity: 0 },
                });
                navigate("/lms");
              }}
            >
              <ListItemIcon>
                <ArrowRightIcon
                  style={{
                    fontSize: 35,
                    marginLeft: "0px -100px 0px 35px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </ListItemIcon>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                LMS Login
              </div>
            </ListItem>
            <ListItem
              style={{ cursor: "pointer" }}
              key={"4"}
              onClick={() => {
                dispatch({
                  type: "NAV_COLOR",
                  payload: { navColor: "transparent", navOpacity: 0 },
                });
                navigate("/alumni");
              }}
            >
              <ListItemIcon>
                <ArrowRightIcon
                  style={{
                    fontSize: 35,
                    marginLeft: "0px -100px 0px 35px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </ListItemIcon>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                Alumni
              </div>
            </ListItem>
            <ListItem
              style={{ cursor: "pointer" }}
              key={"5"}
              onClick={() => {
                dispatch({
                  type: "NAV_COLOR",
                  payload: { navColor: "transparent", navOpacity: 0 },
                });
                navigate("/overview");
              }}
            >
              <ListItemIcon>
                <ArrowRightIcon
                  style={{
                    fontSize: 35,
                    marginLeft: "0px -100px 0px 35px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </ListItemIcon>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                Admission
              </div>
            </ListItem>
            <ListItem
              style={{ cursor: "pointer" }}
              key={"6"}
              onClick={() => {
                dispatch({
                  type: "NAV_COLOR",
                  payload: { navColor: "transparent", navOpacity: 0 },
                });
                navigate("/campus");
              }}
            >
              <ListItemIcon>
                <ArrowRightIcon
                  style={{
                    fontSize: 35,
                    marginLeft: "0px -100px 0px 35px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </ListItemIcon>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                Campus
              </div>
            </ListItem>
          </List>
          <Divider />

          <List>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "15px 0px 15px 15px",
              }}
            >
              <PersonIcon
                style={{
                  fontSize: 35,
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              />
              <Select
                displayEmpty
                sx={{
                  padding: "0px",
                  height: "20px",
                  fontSize: "25px",
                  borderRadius: "50px",
                  borderColor: "blue",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  "& .MuiSvgIcon-root": {
                    color: themeStyle.primaryColor,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover": {
                    "&& fieldset": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                value={studentLife}
                onChange={(e) => {
                  dispatch({
                    type: "NAV_COLOR",
                    payload: { navColor: "transparent", navOpacity: 0 },
                  });
                  navigate(e.target.value);
                }}
              >
                <MenuItem
                  disabled
                  className=""
                  value=""
                  onClick={() => {}}
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    display: "none",
                    fontSize: "20px !important",
                  }}
                >
                  Student Life
                </MenuItem>
                <MenuItem
                  className="nav-menu-item"
                  value="/student_life"
                  onClick={() => {
                    dispatch({
                      type: "NAV_COLOR",
                      payload: { navColor: "transparent", navOpacity: 0 },
                    });
                    navigate("/student_life");
                  }}
                >
                  Overview
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/gallery"}>
                  Gallery
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/campus"}>
                  Campus Experience
                </MenuItem>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "0px 0px 15px 15px",
              }}
            >
              {" "}
              <AddTaskIcon
                className="nav-icon"
                style={{
                  fontSize: 35,
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              />
              <Select
                displayEmpty
                sx={{
                  padding: "0px",
                  height: "20px",
                  borderRadius: "50px",
                  fontSize: "25px",
                  borderColor: "blue",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  "& .MuiSvgIcon-root": {
                    color: themeStyle.primaryColor,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover": {
                    "&& fieldset": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                value={admission}
                onChange={(e) => {
                  dispatch({
                    type: "NAV_COLOR",
                    payload: { navColor: "transparent", navOpacity: 0 },
                  });
                  navigate(e.target.value);
                }}
              >
                <MenuItem
                  disabled
                  className="nav-menu-item"
                  value=""
                  onClick={() => {}}
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    display: "none",
                  }}
                >
                  Admissions
                </MenuItem>
                <MenuItem
                  className="nav-menu-item"
                  value="/overview"
                  onClick={() => {
                    dispatch({
                      type: "NAV_COLOR",
                      payload: { navColor: "transparent", navOpacity: 0 },
                    });
                    navigate("/overview");
                  }}
                >
                  Overview
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/how_to_apply"}>
                  How to Apply
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/apply"}>
                  Apply Now
                </MenuItem>
              </Select>
            </div>
            <Link
              style={{
                textDecoration: "none",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
              to={"/contact"}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "0px 0px 15px 15px",
                }}
              >
                <HandshakeIcon
                  className="nav-icon"
                  style={{
                    fontSize: 35,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
                <div
                  style={{
                    marginLeft: "10px",
                    fontFamily: "Montserrat",
                    fontSize: "25px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  Contact
                </div>
              </div>
            </Link>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "0px 0px 15px 15px",
              }}
            >
              {" "}
              <SportsBasketballIcon
                className="nav-icon"
                style={{
                  fontSize: 35,
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              />
              <Select
                displayEmpty
                inputProps={{
                  MenuProps: {
                    classes: {
                      paper: { backgroundColor: "red" },
                    },
                  },
                }}
                sx={{
                  padding: "0px",
                  height: "20px",
                  fontSize: "25px",
                  borderRadius: "50px",
                  borderColor: "blue",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  "& .MuiSvgIcon-root": {
                    color: themeStyle.primaryColor,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover": {
                    "&& fieldset": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                value={facility}
                onChange={(e) => {
                  dispatch({
                    type: "NAV_COLOR",
                    payload: { navColor: "transparent", navOpacity: 0 },
                  });
                  navigate(e.target.value);
                }}
              >
                <MenuItem
                  disabled
                  className="nav-menu-item"
                  value=""
                  onClick={() => {}}
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    display: "none",
                  }}
                >
                  Facilities
                </MenuItem>
                <MenuItem
                  className="nav-menu-item"
                  value="/facilities"
                  onClick={() => {
                    dispatch({
                      type: "NAV_COLOR",
                      payload: { navColor: "transparent", navOpacity: 0 },
                    });
                    navigate("/facilities");
                  }}
                >
                  Overview
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/classroom"}>
                  Classroom
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/boys_hostel"}>
                  Boys Hostel
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/girls_hostel"}>
                  Girls Hostel
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/library"}>
                  Library
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/computer_labs"}>
                  Computer Labs
                </MenuItem>

                <MenuItem className="nav-menu-item" value={"/transportation"}>
                  Transportation
                </MenuItem>
                <MenuItem className="nav-menu-item" value={"/playground"}>
                  Playground
                </MenuItem>
              </Select>
            </div>
          </List>
        </Drawer>
      ) : (
        <Fragment></Fragment>
      )}
    </Box>
  );
}

export default Sidebar;
