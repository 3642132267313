import React from "react";
import { themeStyle, themeStyleDark } from "../components/Components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CountUp from "react-countup";

export default function Benchmark() {
  const state = useSelector((state) => state);

  return (
    <div
      className="aboutabout4"
      style={{
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.primaryColor
            : themeStyle.primaryColor,
        color:
          state.auth.theme == "dark"
            ? themeStyleDark.primaryButtonTextColor
            : themeStyle.primaryButtonTextColor,
      }}
    >
      <div
        style={{
          fontSize: "55px",
          fontWeight: "bold",
          fontFamily: "Montserrat",
        }}
      >
        Setting Benchmark in Quality Education Since 1959
      </div>
      <br />
      <br />
      <Grid container spacing={2.5} style={{ width: "80%" }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className="icard" style={{ backgroundColor: "white" }}>
            <CountUp
              end={100}
              duration={100}
              onEnd={() => {}}
              style={{
                fontFamily: "Montserrat",
                fontSize: "75px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                marginTop: "-30px",
                paddingBottom: "20px",
                fontSize: "30px",
                fontWeight: "bold",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontFamily: "Montserrat",
              }}
            >
              Educators
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className="icard" style={{ backgroundColor: "white" }}>
            <CountUp
              end={10}
              duration={100}
              onEnd={() => {}}
              style={{
                fontFamily: "Montserrat",
                fontSize: "75px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                marginTop: "-30px",
                paddingBottom: "20px",
                fontSize: "30px",
                fontWeight: "bold",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontFamily: "Montserrat",
              }}
            >
              Campuses
            </div>
          </div>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className="icard" style={{ backgroundColor: "white" }}>
            <CountUp
              end={1200}
              duration={100}
              onEnd={() => {}}
              style={{
                fontFamily: "Montserrat",
                fontSize: "75px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                marginTop: "-30px",
                paddingBottom: "20px",
                fontSize: "30px",
                fontWeight: "bold",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontFamily: "Montserrat",
              }}
            >
              Students
            </div>
          </div>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className="icard" style={{ backgroundColor: "white" }}>
            <CountUp
              end={5240}
              duration={100}
              onEnd={() => {}}
              style={{
                fontFamily: "Montserrat",
                fontSize: "75px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                marginTop: "-30px",
                paddingBottom: "20px",
                fontSize: "30px",
                fontWeight: "bold",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                fontFamily: "Montserrat",
              }}
            >
              New admissions
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
