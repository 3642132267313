import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";



export default function Classroom() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1577896851231-70ef18881754?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Classrooms
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            {" "}
            At BGS World School, our classrooms are thoughtfully designed to
            provide an optimal learning environment for our students. Spacious,
            well-lit, and equipped with modern teaching aids, these classrooms
            offer a conducive space for intellectual engagement and interaction.
            Whether it’s a lecture for our B.Com program, a group discussion in
            a BBA course, or a seminar for M.Com students, our classrooms can
            adapt to varied teaching methods and learning preferences.
          </b>
          <br />
          <br />
          Beyond their physical features, our classrooms are where the
          transformational journey of learning takes place. Here, ideas are
          shared, perspectives are broadened, and critical thinking is
          cultivated. Our experienced faculty bring these rooms to life with
          their expertise, passion, and innovative teaching techniques. At BGS
          World School, we understand that the classroom experience is a crucial
          part of our students’ academic journey. Thus, we strive to ensure that
          our classrooms are not just spaces for instruction, but environments
          that inspire curiosity, foster understanding, and stimulate
          intellectual growth.
        </div>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            Innovative Learning Spaces
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            At BGS World School, our classrooms are designed to foster a dynamic
            and interactive learning environment. Each classroom is equipped
            with state-of-the-art technology, including smart boards,
            projectors, and high-speed internet, allowing for a seamless
            integration of digital resources into the curriculum. The furniture
            is ergonomically designed to ensure comfort and flexibility,
            promoting collaboration among students. Ample natural light and
            vibrant colors create a stimulating atmosphere, encouraging
            creativity and focus. The layout of our classrooms supports a
            variety of teaching methodologies, from traditional lectures to
            group work and hands-on activities, ensuring that every student’s
            learning style is accommodated.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1510531704581-5b2870972060?q=80&w=1473&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Student-Centered Environment
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Our classrooms at BGS World School are more than just physical
                spaces; they are hubs of exploration and discovery. We emphasize
                a student-centered approach, where the classroom environment is
                tailored to meet the diverse needs of our learners. Teachers use
                differentiated instruction to cater to different learning paces,
                ensuring that every student is challenged and supported. The
                classrooms are equipped with various learning stations, where
                students can engage in activities that suit their individual
                learning styles. This setup encourages self-directed learning
                and critical thinking, enabling students to take ownership of
                their education. By fostering an inclusive and supportive
                environment, we help students develop confidence and a love for
                learning.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Community and Collaboration
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Collaboration is at the heart of the learning experience at BGS
                World School. Our classrooms are designed to facilitate group
                work and peer learning, with spaces that encourage students to
                work together on projects and assignments. Interactive
                whiteboards and shared digital platforms allow students to
                collaborate in real-time, both in the classroom and remotely.
                Teachers play a guiding role, fostering a sense of community and
                cooperation among students. This collaborative approach not only
                enhances academic learning but also helps students develop
                essential social and communication skills. By creating a
                classroom environment that values teamwork, we prepare our
                students to thrive in a globally connected world.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1509062522246-3755977927d7?q=80&w=1532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
