import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";

export default function Principal() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://plus.unsplash.com/premium_photo-1670315264879-59cc6b15db5f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Principal{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          ‘Time and tide’ waits for none. Rapid globalisation and
          hypercompetitive markets make it imperative to align management
          education, to effectively meet the dynamics of corporate challenges.
          With the growing awareness about the complexity of modern business;
          the demand for management and commerce graduates, with a high level of
          domain knowledge and core competencies is fast increasing. BGS
          Institute of Management Studies alive to this emerging trend provides
          market-oriented knowledge and skills to the students.
          <br /> <br />
          Right from its inception in 2007, the Institute has continuously
          evolved in many innovative ways of learning and experience sharing,
          with a futuristic vision and mission-oriented goals. BGS World School
          has established an excellent learning environment with a moral and
          spiritual atmosphere. The institute offers industry-focused training
          and development programmes to all students to meet competitive world
          expectations. This has been made possible by highly qualified and
          experienced faculty and state-of-the-art infrastructure.
          <br />
          <br /> Our education also emphasizes on all-round personality
          development and interactive practical learning through close
          interfacing with lead companies to groom our students as future
          corporate leaders. BGS World School moves ahead into the challenging
          future with the mission to carry forward its goals and set new
          milestones in the era of world-class modern management education. I
          welcome all the aspirants to BGS World School and wish them a happy
          and prosperous future ahead.
        </div>
        <div className="aboutabout2" style={{ marginTop: "-300px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "50px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                About BGS World School
              </div>
              <br />
              <div
                style={{
                  fontSize: "25px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                At BGS World School, our classrooms are thoughtfully designed to
                provide an optimal learning environment for our students.
                Spacious, well-lit, and equipped with modern teaching aids,
                these classrooms offer a conducive space for intellectual
                engagement and interaction. Whether it’s a lecture for our B.Com
                program, a group discussion in a BBA course, or a seminar for
                M.Com students, our classrooms can adapt to varied teaching
                methods and learning preferences. Beyond their physical
                features, our classrooms are where the transformational journey
                of learning takes place.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "30px 0px 10px 0px",
                }}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonTextColor
                        : themeStyle.primaryButtonTextColor,
                  }}
                  to={"/about"}
                >
                  <Button
                    style={{
                      padding: "30px",
                      border:
                        "2px " + state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor + " solid",
                      borderRadius: "45px",
                      height: "30px",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  >
                    Read More
                  </Button>
                </Link>
              </div>
            </Grid>

            <Grid item xs={5}>
              <div className="slider">
                <div className="slides-container">
                  <div className="slides-inner">
                    <div className="slide">
                      <img
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          margin: "10px",
                          height: "100%",
                        }}
                        src={
                          "https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                      />
                    </div>
                    <div className="slide">
                      <img
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          margin: "10px",
                          height: "100%",
                        }}
                        src={
                          "https://images.unsplash.com/photo-1523050854058-8df90110c9f1?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                      />
                    </div>
                    <div className="slide">
                      <img
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          margin: "10px",
                          height: "100%",
                        }}
                        src={
                          "https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="controls">
                  <Button
                    className="button-carousel"
                    id="prevButton"
                    style={{
                      border: "2px solid " + themeStyle.primaryColor,
                      borderRadius: "25px",
                      height: "30px",
                      fontSize: "15px",
                      color: themeStyle.primaryColor,
                      fontWeight: "bold",
                      padding: "2px 10px 0px 10px",
                    }}
                  >
                    Prev
                  </Button>

                  <Button
                    className="button-carousel"
                    id="nextButton"
                    style={{
                      marginLeft: "15px",
                      border: "2px solid " + themeStyle.primaryColor,
                      borderRadius: "25px",
                      height: "30px",
                      fontSize: "15px",
                      color: themeStyle.primaryColor,
                      fontWeight: "bold",
                      padding: "2px 10px 0px 10px",
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
