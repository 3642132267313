import HistoryIcon from "@mui/icons-material/History";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SchoolIcon from "@mui/icons-material/School";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import RadarIcon from "@mui/icons-material/Radar";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import ClassIcon from "@mui/icons-material/Class";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { useSelector, useDispatch } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import { Grid } from "@mui/material";



export default function Explore() {
  const state = useSelector((state) => state);

  return (
    <div
      className="news"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "100px",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginBottom: "50px",
          marginTop: "-100px",
          fontSize: "55px",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color:
            state.auth.theme == "dark"
              ? themeStyleDark.primaryColor
              : themeStyle.primaryColor,
        }}
      >
        Explore BGS World School{" "}
      </div>
      <Grid
        container
        spacing={10}
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <HistoryIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Time Tested Legacy
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AutorenewIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Modern Yet Traditional
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SchoolIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Holistic Curriculum
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={10}
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AllInclusiveIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Inclusive Education
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RadarIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Excellence
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CastForEducationIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Judicious Assessment
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={10}
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FavoriteIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Passionate Teachers
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CurrencyRupeeIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Affordable Education
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LibraryMusicIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Music{" "}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={10}
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelfImprovementIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Yoga{" "}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SportsBasketballIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Sports and Games
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AcUnitIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Cultural Activities
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={10}
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ClassIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              After School Sports
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CorporateFareIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Pristine Campuses
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <HistoryEduIcon
              className="nav-icon"
              style={{
                fontSize: 40,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            />
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px",
                marginTop: "5px",
                fontFamily: "Montserrat",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              Well-Ventilated Classes
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
