import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import Slider from "./Slider";

export default function AboutSchool() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            About BGS World School
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          At BGS World School, our classrooms are thoughtfully designed to
          provide an optimal learning environment for our students. Spacious,
          well-lit, and equipped with modern teaching aids, these classrooms
          offer a conducive space for intellectual engagement and interaction.
          Whether it’s a lecture for our B.Com program, a group discussion in a
          BBA course, or a seminar for M.Com students, our classrooms can adapt
          to varied teaching methods and learning preferences.
          <br />
          <br />
          Beyond their physical features, our classrooms are where the
          transformational journey of learning takes place. Here, ideas are
          shared, perspectives are broadened, and critical thinking is
          cultivated. Our experienced faculty bring these rooms to life with
          their expertise, passion, and innovative teaching techniques. At BGS
          World School, we understand that the classroom experience is a crucial
          part of our students’ academic journey. Thus, we strive to ensure that
          our classrooms are not just spaces for instruction, but environments
          that inspire curiosity, foster understanding, and stimulate
          intellectual growth.
        </div>
        <Slider/>
        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
