import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";

export default function About() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1521587760476-6c12a4b040da?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Library
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            {" "}
            BGS World School is home to a well-equipped library that serves as
            the intellectual hub of our college. Our library houses an extensive
            collection of resources, including textbooks, reference books,
            research papers, and journals across various disciplines. These
            resources are meticulously curated to support the academic needs of
            our B.Com, BBA, and M.Com students. The library also offers a
            digital database, providing access to numerous e-books and online
            journals, thus supporting the diverse learning needs of our
            students.
          </b>
          <br />
          <br />
          Beyond just being a repository of information, the library at BGS
          World School is a conducive space for study and intellectual
          exploration. Comfortable seating, quiet study areas, and group
          discussion rooms ensure that all students can find a space that suits
          their study preferences. The library staff are always ready to assist
          students in finding resources and utilizing the library services
          effectively. The library is more than just a place to find books; it’s
          a space that fosters a culture of academic excellence, intellectual
          curiosity, and lifelong learning.
        </div>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            A Hub of Knowledge and Discovery
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            The BGS World School library is a cornerstone of our academic
            environment, providing students with access to a vast collection of
            resources that span various subjects and genres. Our library boasts
            an extensive range of books, from classic literature and modern
            fiction to academic texts and reference materials, catering to
            students of all ages and interests. Additionally, we offer a rich
            selection of digital resources, including e-books, academic
            journals, and multimedia content, ensuring that students have the
            tools they need for research and learning. The library is designed
            to be a quiet and inviting space, where students can immerse
            themselves in reading, study, and intellectual exploration.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1481627834876-b7833e8f5570?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Supporting Academic Excellence
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                At BGS World School, the library plays a critical role in
                supporting academic excellence. Our dedicated librarians work
                closely with teachers to curate resources that align with the
                school curriculum, enhancing classroom learning with additional
                materials that deepen students' understanding of various
                subjects. The library also offers tailored programs, such as
                reading clubs, research workshops, and literary events, aimed at
                developing students' critical thinking, research skills, and
                love for reading. With comfortable seating, individual study
                areas, and group discussion rooms, the library is an ideal
                environment for both independent study and collaborative
                learning, ensuring that students have the support they need to
                excel academically.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Fostering a Lifelong Love for Reading
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                The library at BGS World School is more than just a place for
                academic study; it is a space that fosters a lifelong love for
                reading and learning. We believe in nurturing students'
                curiosity and encouraging them to explore diverse topics through
                books and other resources. Our library regularly hosts author
                visits, book fairs, and storytelling sessions, providing
                students with opportunities to engage with literature in
                creative and exciting ways. Special reading programs and
                challenges are also organized to motivate students to discover
                new genres and authors, broadening their literary horizons. By
                creating a vibrant and engaging library environment, we aim to
                instill in our students a passion for reading that will stay
                with them throughout their lives.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1505664194779-8beaceb93744?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
