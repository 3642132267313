import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import { API_URL } from "../actions/auth";

export default function SportPage() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);
  const location = useLocation();

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1566932769119-7a1fb6d7ce23?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Sports{" "}
          </div>
        </div>
        <div className="aboutabout" style={{marginTop:"100px"}}>
          <Grid
            container
            spacing={2.5}
            style={{ height: "20vh", width: "70%" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{ width: "100%" }}
            >
              <div
                style={{
                  textAlign: "left",
                  fontSize: "38px",
                  fontFamily: "Montserrat",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                  marginBottom: "25px",
                }}
              >
                {location.state.title}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  color:
                  "grey",
                  fontWeight: "bold",
                  marginTop: "25px",
                }}
              >
                {location.state.subtitle}
              </div>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{}}>
              <img
                style={{
                  width:"100%",
                  objectFit: "fill",
                  height: "100%",
                }}
                src={API_URL + "/media/" + location.state.image}
              />
            </Grid>
          </Grid>

          <div
            style={{
              textAlign:"justify",
              width: "70%",
              marginTop:"350px",
              fontSize: "25px",
              fontFamily: "Montserrat",
              marginBottom:"100px"
            }}
          >
            {location.state.content}
          </div>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
