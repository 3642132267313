import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";


export default function About() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1628471047004-f4fe9a1d9e18?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Transportation{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            {" "}
            At BGS World School, we understand the importance of easy and efficient
            transportation for our students and staff, which is why we offer
            comprehensive transportation services. Our fleet of vehicles
            provides regular services between the college and various key points
            in the city. This ensures that students from all our programs –
            B.Com, BBA, and M.Com – as well as our staff members can commute to
            and from the college with ease and punctuality. Our vehicles are
            well-maintained and driven by experienced drivers to ensure the
            safety and comfort of all passengers.
          </b>
          <br />
          <br />
          Beyond just providing a means of travel, our transportation services
          are a testament to our commitment to creating a stress-free and
          supportive learning environment. We understand that a reliable
          transport system contributes to the overall well-being and academic
          success of our students by minimizing travel-related stress and
          maximizing convenience. The routes and schedules of our transport
          services are designed with the needs of our students and staff in
          mind, ensuring timely pick-ups and drop-offs. At BGS World School, we strive to
          make your journey with us as smooth as possible, both acadically and
          literally.
        </div>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            Safe and Reliable Transportation
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            BGS World School prioritizes the safety and convenience of our
            students with a well-organized transportation system. Our fleet of
            modern, air-conditioned buses is meticulously maintained to ensure
            the highest standards of safety and comfort. Each bus is equipped
            with GPS tracking, allowing parents and school authorities to
            monitor the location and movement of the buses in real-time. Trained
            and experienced drivers, along with courteous support staff, ensure
            that students are transported securely and on time. All buses adhere
            to stringent safety protocols, including regular inspections, speed
            limits, and mandatory safety drills, providing parents with peace of
            mind that their children are in safe hands.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1659517882408-1134468ca400?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Comprehensive Coverage Across the City
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                To cater to the diverse needs of our student body, BGS World
                School offers extensive transport coverage across the city and
                surrounding areas. Our bus routes are carefully planned to
                ensure that students from various neighborhoods have easy access
                to the school. Pickup and drop-off points are strategically
                located to minimize travel time and ensure convenience for both
                students and parents. The transport schedule is designed to
                align with the school timetable, ensuring punctuality and
                minimal wait times. Our transport services are flexible, with
                options to accommodate students participating in extracurricular
                activities, ensuring that they can safely return home even after
                late sessions.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Commitment to Safety and Comfort
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                At BGS World School, the safety and comfort of our students
                during their commute is paramount. In addition to regular
                vehicle maintenance, our buses are fitted with safety features
                such as seat belts, first aid kits, and fire extinguishers. We
                conduct routine safety drills with students to prepare them for
                emergencies, and our staff is trained in first aid and emergency
                response. The bus interiors are designed to be comfortable, with
                spacious seating and climate control to ensure a pleasant
                journey, regardless of the weather. By providing a reliable and
                secure transportation service, we ensure that students arrive at
                school ready to learn and return home safely, contributing to
                their overall well-being and academic success.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://plus.unsplash.com/premium_photo-1661634073903-2ecdccdfc8a2?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
