import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";



export default function CAO() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1664575602276-acd073f104c1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Chief Administrative Officer
          </div>
        </div>

        <div className="aboutabout2">
          <Grid container spacing={5}  style={{ marginTop: "-150px", }}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "48px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                About CAO{" "}
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Learning environment is said that the social and emotional
                environment which is invisible to teacher. Students react to the
                environment which is a composite of many factors and particular
                aspects of the environment effect changes in particular areas of
                the cognitive development at certain critical points in time and
                plays a crucial and influential role in the cognitive
                development process of the students. These environmental factors
                provide a richly structured milieu of experience, which affects
                the student’s far reaching manner, and they might shape and
                determine many of the student’s preferences, habits etc., and
                will consciously and subconsciously influence many of their
                behaviours and actions. We are all well aware of the fact that
                cognitive development normally and naturally takes place within
                an environment; therefore a lot of emphasis is placed on shaping
                the college’s environment to be ‘student-friendly’ and
                ‘student-centric’. Such a nurturing environment will enhance
                their experience and exposure and also will to increase their
                self –worth. At this college, the environment in which learning
                occurs is scientifically planned and specially created to
                achieve the stated objectives and desired goals, sparing no
                expenses. The emphasis will be on providing an uplifting social
                and academic environment in which the student can grow, be
                taught and learn the relevance of and respect for all the things
                in life.
                <br />
                <br />
                <b>
                  Dr. N. Shivarama Reddy
                  <br />
                  Chief Administrative Officer
                </b>
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://bgsims.in/wp-content/uploads/2023/08/NSr-755x1024.jpg"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
