import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";



export default function About() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1569653402334-2e98fbaa80ee?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Computer Labs{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            {" "}
            At BGS World School, we understand the integral role that technology plays in
            modern education. This is why we’ve equipped our campus with
            state-of-the-art computer labs. These labs are designed to support
            the needs of our B.Com, BBA, and M.Com students, enabling them to
            conduct research, complete projects, and gain practical experience
            with the latest software tools relevant to their fields of study.
            High-speed internet connectivity and the latest hardware provide a
            conducive environment for students to learn and apply their skills
            effectively.
          </b>
          <br />
          <br />
          Our computer labs also serve as a hub for collaboration and
          innovation. Students use these facilities to work on group
          assignments, engage in discussions, and exchange ideas. The labs are
          maintained by our dedicated IT staff who ensure the smooth functioning
          of all equipment and are readily available to assist students with any
          technical issues. By providing these resources, BGS World School is committed to
          ensuring that our students are technologically adept and prepared for
          the digital demands of the modern workplace.
        </div>
        <div className="aboutabout">
          <div
            style={{
              fontSize: "28px",
              fontFamily: "Montserrat",
              textAlign: "center",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            State-of-the-Art Technology and Resources
          </div>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            The computer labs at BGS World School are equipped with
            state-of-the-art technology, providing students with access to the
            latest tools and software essential for modern learning. Each lab
            features high-performance computers, fast internet connectivity, and
            advanced software that supports a wide range of subjects, from basic
            computing skills to complex programming and graphic design. The labs
            are designed to accommodate individual and group work, with spacious
            workstations that ensure students can work comfortably on their
            projects. Additionally, the labs are equipped with interactive
            whiteboards and projection systems, allowing teachers to deliver
            engaging and interactive lessons that make complex concepts easier
            to understand.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1576669801838-1b1c52121e6a?q=80&w=1353&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Hands-On Learning and Skill Development
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                BGS World School’s computer labs are integral to our hands-on
                learning approach, where students apply theoretical knowledge
                through practical activities. The labs provide an environment
                where students can experiment with coding, digital design, data
                analysis, and more, fostering critical thinking and
                problem-solving skills. From early grades to advanced levels,
                students are encouraged to explore various aspects of
                technology, guided by experienced instructors who offer
                personalized support. Regularly updated software and resources
                ensure that students are learning the most current technologies,
                preparing them for future academic pursuits and careers in an
                increasingly digital world. By emphasizing experiential
                learning, the computer labs play a crucial role in developing
                students’ technical proficiency and confidence.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                Promoting Digital Literacy and Innovation
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                At BGS World School, we recognize the importance of digital
                literacy in today’s world, and our computer labs are designed to
                cultivate these essential skills in every student. The labs are
                not just places for learning; they are innovation hubs where
                students are encouraged to think creatively and develop new
                ideas. Through various projects and competitions, students get
                opportunities to create their own apps, games, websites, and
                digital art, pushing the boundaries of their creativity. The
                curriculum is designed to integrate technology across all
                subjects, ensuring that students become adept at using digital
                tools in a variety of contexts. By promoting digital literacy
                and innovation, our computer labs prepare students to thrive in
                a technology-driven future.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={
                  "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </Grid>
          </Grid>
        </div>

        <Benchmark />

        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
