import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";


export default function About() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:"linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://plus.unsplash.com/premium_photo-1716903508664-8e23f6ba4331?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')"
        }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            About Math
          </div>
        </div>
        <div className="aboutabout" style={{marginTop:"100px"}}>
          <div
            style={{
              fontSize: "25px",
              fontFamily: "Montserrat",
              textAlign: "left",
            }}
          >
            Sri Kshetra Adi Chunchanagiri has a history of 2000 years of its
            existence. It is the holy land engulfed with the divine resonance
            and vibration. Ancient Vedic culture was founded here on
            spirituality, where Nature is valued and worshipped. It was Yagnas
            and prayers that inspired cosmic peace here.
            <br /> Sri Kshetra is blessed by Lord Shiva. Lord Shiva performed
            penance at Sri Kshetra, during which he devoured two demons, Chuncha
            and Kancha, who were pestering elements of the area for a very long
            time. At the end of his austerity, Lord Shiva entrusted a Siddayogi,
            establishing a ‘Natha tradition’ and guided him to disseminate the
            righteousness in the society. <br />
            Lord Shiva also assured to reside in Sri Kshetra Adichunchanagiri,
            in the form of Panchalingas viz. Lord Gangadhareshwaraswamy,
            Chandramouleshwaraswamy, Malleshwaraswamy, Siddeshwaraswamy and
            Someshwaraswamy; of which Lord Gangadhareshwaraswamy is known as the
            ‘Presiding Deity’. Thus, Sri Kshetra came to be known as
            “Panchalinga Kshetra”.
          </div>
        </div>
        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={require("../img/about2.png")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                His Holiness Parama Poojya Jagadguru Bhairavaikya Sri Sri Sri
                Dr. Balagangadharanatha Swamiji 71nd Pontiff, Sri
                Adichunchanagiri Mahasamsthana Math Founder President, Sri
                Adichunchanagiri Shikshana Trust (500 institutions)
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Sri Sri Sri Dr. Balagangadharanatha Swamiji (1945 – 2013) was a
                revered religious leader and a visionary social reformer who
                dedicated his life to the service of God and humanity. He was
                the 71st pontiff of the ancient Natha Center of Worship, Shri
                Adichunchanagiri Math, in Karnataka. He was born on 18th January
                1945 as Gangadharaiah to a farming family in Bananduru village.
                He renounced his worldly life at the age of 19 and became a
                disciple of Shri Ramanandanatha Swamiji, who initiated him into
                the Natha Pantha tradition. He took over as the head of the
                Adichunchanagiri Math on 24th September 1974 and transformed it
                into a hub of spiritual, educational, cultural and social
                activities. <br />
                <br />
                Dr. Balagangadharanatha Swamiji was a compassionate and generous
                soul who worked tirelessly for the upliftment of the poor, the
                downtrodden and the marginalized sections of the society. He
                established hundreds of educational institutions, hospitals,
                orphanages, old age homes, cow shelters and other welfare
                projects across India and abroad. He also promoted
                inter-religious harmony, environmental protection, organic
                farming, rural development and women empowerment. He was a
                patron of arts, literature, music and sports and encouraged
                young talents to pursue their dreams. He was also a great
                scholar of Vedas, Upanishads, Agamas and other sacred texts and
                imparted his wisdom to millions of devotees who flocked to his
                ashram. <br />
                <br /> Dr. Balagangadharanatha Swamiji was honoured with many
                awards and recognitions for his outstanding contributions to the
                society. He received the Padma Bhushan, India’s third highest
                civilian honour, in 2010. He also received honorary doctorates
                from several universities and accolades from various religious
                and cultural organizations. He inspired millions of people with
                his message of love, peace and service. He was a symbol of hope
                and compassion for the needy and the suffering. He was a true
                embodiment of the motto “Service to Humanity is Service to God”.
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="aboutabout2" style={{ marginTop: "-150px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <div
                style={{
                  fontSize: "28px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontWeight: "bold",
                }}
              >
                His Holiness Parama Poojya Jagadguru Sri Sri Sri Dr.
                Nirmalanandanatha Mahaswamiji 72nd Pontiff, Sri Adichunchanagiri
                Mahasamsthana Math Chancellor, Adichunchanagiri University
                President, Sri Adichunchanagiri Shikshana Trust (500
                institutions)
              </div>
              <br />
              <div
                style={{
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Poojya Mahaswamiji, unlike many other youngsters, had an
                inclination towards Spirituality, Science and Service rather
                than Materialism. He adapted an ascetic life in 1998 and
                underwent formal training in the traditional knowledge systems.
                His unquenchable thirst for knowledge is evident from his
                attitude in conferences and functions wherein he listens to the
                discourses on Vedas, Upanishads and other Shastras like an eager
                young student.
                <br />
                <br /> Poojya Mahaswamiji passed his Diploma with Distinction
                securing 7th Rank for the State from Government Polytechnic
                College, Tumkur. Later he passed his graduation in Civil
                Engineering from National Institute of Engineering, Mysore, and
                did his M.Tech in Structural Engineering from Indian Institute
                of Technology, Chennai. Swamiji was influenced by Life’s
                Practical Philosophy of Sri Sri Sri Dr. Bala Gangadharanatha
                Mahaswamiji. With a zeal to understand the eternal truth he
                studied Master’s degree in Sanskrit Advaitha Vedantha. <br />
                <br />
                The Vision of Poojya Mahaswamiji is to follow the footsteps of
                his Guru, working tirelessly to “Preserve, Promote, Pursue and
                Progress with Passion in the Path of his Patriarch”. By
                considering the yeoman services to society, University of Mysore
                conferred the Honorary Doctorate of D.Litt. (Honoris Causa) on
                April 30th 2016 at Sri Kshetra Adichunchanagiri. Mahaswamiji was
                awarded PhD degree from Gulbarga University for the recognition
                of the thesis work entitled ‘Natha sampradaya a cultural study’
                on 11th March 2019.
              </div>
            </Grid>
            <Grid item xs={5}>
              <img
                style={{ width: "100%", objectFit: "cover", margin: "10px" }}
                src={require("../img/about1.png")}
              />
            </Grid>
          </Grid>
        </div>

        <VisitUs/>
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
