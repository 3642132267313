import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import VisitUs from "../components/VisitUs";
import axios from "axios";
import { API_URL } from "../actions/auth";
import { alertMessage } from "../actions/auth";


export default function Contact() {
  const state = useSelector((state) => state);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function onSubmit(e) {
    e.preventDefault();
    var body={};
    body['name'] = name;
    body['email'] = email;
    body['phone_number'] = phoneNumber;
    body['subject'] = subject;
    body['content'] = description;

    axios
    .post(API_URL + "new_contact",JSON.stringify(body))
    .then((res) => {
      dispatch(alertMessage("SUCCESS", "SUCCESS", "Your request has been submitted. Our team will get back to you."));

    })
    .catch((err) => {});
  }

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1709715357479-591f9971fb05?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Contact Us
          </div>
        </div>
        <div className="aboutabout" style={{marginTop:"100px"}}>
          <Grid container spacing={5}>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  fontSize: "55px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              >
                Ask us anything. Anytime.
              </div>
              <br />
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocationOnIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    BB Road, Chickballapur, 562101
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EmailIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    principal@bgsworldschool.ac.in
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    +91 7676637525
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Reception: 08156 200183{" "}
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Registrar: 08156 200185{" "}
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Principal: 08156 200184
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Account Section: 08156 200187{" "}
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Fax: 08156 200186
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7} style={{ justifyContent: "center" }}>
              <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                            fontFamily: "Montserrat",
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      placeholder="Name *"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                            fontFamily: "Montserrat",
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      placeholder="E-mail *"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                            fontFamily: "Montserrat",
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Phone number *"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                            fontFamily: "Montserrat",
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="subject"
                      autoComplete="subject"
                      autoFocus
                      placeholder="Subject *"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  sx={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.backgroundColor
                        : themeStyle.backgroundColor,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                    borderRadius: "6px",
                    height: "400",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid " + themeStyle.primaryColor,
                    },
                    "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                      {
                        color: themeStyle.primaryColor,
                        fontWeight: "bold",
                        opacity: 1,
                        fontFamily: "Montserrat",
                      },
                  }}
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  name="description"
                  autoComplete="description"
                  multiline
                  rows={7}
                  autoFocus
                  placeholder="Description of your enquiry *"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    dispatch({
                      type: "NAV_COLOR",
                      payload: { navColor: "#444", navOpacity: 1 },
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "60px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonColor
                          : themeStyle.primaryButtonColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonTextColor
                          : themeStyle.primaryButtonTextColor,
                    }}
                    type="submit"
                    variant="contained"
                  >
                    <span
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                      }}
                    >
                      SUBMIT REQUEST
                    </span>
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
