import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { alertMessage } from "../actions/auth";



export default function Oops() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 1s",
          opacity: loaded ? 1 : 0,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage:
              "linear-gradient(to right, rgba(33,41,100,0.9),  rgba(33, 41, 100, 0.9)), url(https://img.freepik.com/free-vector/education-pattern-background-doodle-style_53876-115365.jpg?w=740)",
            height: "100%",
            paddingTop: "50px",
          }}
        >
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
            }}
          >
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <img
                style={{ maxHeight: "50px", margin: "10px" }}
                src={require("../img/appbar.png")}
              /> */}
              <a
                style={{ textDecoration: "none" }}
                href="/"
              >
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                >
                  <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
                </Button>
              </a>
            </Toolbar>
          </AppBar>
          <div
            style={{
              padding: "50px",
              backgroundColor: "white",
              textAlign: "center",
              borderRadius: "6px",
            }}
          >
            <div style={{ marginTop: "-30px" }}>404 not found.</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
