import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import VisitUs from "../components/VisitUs";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";


export default function HowToApply() {
  const state = useSelector((state) => state);
  const [studentName, setStudentName] = useState("");
  const [parentName, setParentName] = useState("");

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [subject, setSubject] = useState("");
  const [questions, setQuestions] = useState("");
  const [age, setAge] = useState("");

  const [classs, setClasss] = useState("LKG");
  const navigate = useNavigate();

  useEffect(() => {}, []);

  function onSubmit(e) {
    e.preventDefault();
  }

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1623631633177-7fbb2d3ae94d?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            How To Apply
          </div>
        </div>
        <div style={{ padding: "100px 50px 50px 20%" }}>
          <div style={{ fontSize: "35px", fontWeight: "600" }}>
            Important Dates
          </div>
          <br />
          <div style={{ fontSize: "25px", fontWeight: "600" }}>
            2024 Open House Dates
          </div>
          <p>NCSSM-Durham: Saturday, October 19, 2024</p>
          <p>NCSSM-Morganton: Saturday, November 9, 2024</p>
          <p>
            Middle School Open House: Monday, November 11, 2024 (Morganton
            Campus)
          </p>
          <p>
            *Registration opens one month prior to each event. Registration is
            required.*
          </p>
          <br />
          <br />
          <div style={{ fontSize: "25px", fontWeight: "600" }}>
            2024 Open House Dates
          </div>
          <p>NCSSM-Durham: Saturday, October 19, 2024</p>
          <p>NCSSM-Morganton: Saturday, November 9, 2024</p>
          <p>
            Middle School Open House: Monday, November 11, 2024 (Morganton
            Campus)
          </p>
          <p>
            *Registration opens one month prior to each event. Registration is
            required.*
          </p>
          <br />
          <br />
          <div style={{ fontSize: "25px", fontWeight: "600" }}>
            2024 Open House Dates
          </div>
          <p>NCSSM-Durham: Saturday, October 19, 2024</p>
          <p>NCSSM-Morganton: Saturday, November 9, 2024</p>
          <p>
            Middle School Open House: Monday, November 11, 2024 (Morganton
            Campus)
          </p>
          <p>
            *Registration opens one month prior to each event. Registration is
            required.*
          </p>
        </div>

        <div
          className="aboutabout3"
          style={{
            padding: "50px",
            backgroundColor: themeStyle.primaryColor,
          }}
        >
          <div style={{ fontSize: "35px", fontWeight: "600", color: "white" }}>
            Resources for applicants
          </div>
          <br />
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div
                className="icard"
                style={{
                  padding: "0px 15px 0px 15px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                <p> Application Help</p>
                <ArrowRightIcon
                  className="nav-icon"
                  style={{
                    fontSize: 35,
                    color: "black",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div
                className="icard"
                style={{
                  padding: "0px 15px 0px 15px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                <p> Test Description</p>
                <ArrowRightIcon
                  className="nav-icon"
                  style={{
                    fontSize: 35,
                    color: "black",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div
                className="icard"
                style={{
                  padding: "0px 15px 0px 15px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                <p> Application Essay Questions</p>
                <ArrowRightIcon
                  className="nav-icon"
                  style={{
                    fontSize: 35,
                    color: "black",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div
                className="icard"
                style={{
                  padding: "0px 15px 0px 15px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                <p>Admissions FAQ</p>
                <ArrowRightIcon
                  className="nav-icon"
                  style={{
                    fontSize: 35,
                    color: "black",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div
                className="icard"
                style={{
                  padding: "0px 15px 0px 15px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                <p>Contact Admissions</p>
                <ArrowRightIcon
                  className="nav-icon"
                  style={{
                    fontSize: 35,
                    color: "black",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "50px" }}>
          <div style={{ fontSize: "35px", fontWeight: "600" }}>
            Application process
          </div>
          <br />
          <div style={{ fontSize: "25px", fontWeight: "600" }}>
            2024 Open House Dates
          </div>
          <p>
            Eligible applicants may apply to NCSSM’s Residential (Durham and/or
            Morganton), Online, or Summer Ventures program or all three, using
            one electronic application.{" "}
            <b>
              There is no application fee or cost for tuition, meals,
              room/board, or textbooks for these three programs.
            </b>
          </p>

          <p>
            In order to complete the 2024-25 application process and be
            considered for the NCSSM Class of 2027, potential applicants must
            complete ALL of the requirements within the required time frames.
          </p>

          <b>
            The application process for the Class of 2027 will open on October
            15, 2024, at noon and close on January 5, 2025, at 11:59 p.m. Status
            notification will be released on April 3, 2025.
          </b>
        </div>

        <div style={{ padding: "50px" }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              style={{
                padding: "10px",
                backgroundColor: themeStyle.primaryButtonColor,
                borderRadius: "15px",
                fontWeight: 600,
                color: "white",
                fontSize: "25px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              1) Be Eligible{" "}
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{ fontSize: "18px", fontWeight: 400 }}>
                <li>Applicants must be in their second year of high school.</li>
                <li>
                  Applicants applying for the Class of 2027 must establish
                  permanent legal residency in the state of North Carolina by
                  December 1, 2024, and are required to complete the residency
                  application using the North Carolina Residency Determination
                  Services (RDS) by January 30, 2025.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion defaultExpanded>
            <AccordionSummary
              style={{
                padding: "10px",
                backgroundColor: themeStyle.primaryButtonColor,
                borderRadius: "15px",
                fontWeight: 600,
                color: "white",
                fontSize: "25px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              2) Pass Admission Tests
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: "20px" }}>
            <br />

              <b>

                Standardized Test Update for the Class of 2027 The North
                Carolina School of Science and Mathematics will not require or
                accept standardized tests (SAT, ACT, etc.) for the Class of
                2027.
              </b>
              <br /> The Office of Admissions will not accept any score reports
              as part of the application process. If you have any questions,
              please contact the Office of Admissions, admissions@ncssm.edu.
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion defaultExpanded>
            <AccordionSummary
              style={{
                padding: "10px",
                backgroundColor: themeStyle.primaryButtonColor,
                borderRadius: "15px",
                fontWeight: 600,
                color: "white",
                fontSize: "25px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              3) Complete Application
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: "20px" }}>
            <br />

              NCSSM has partnered with the College Foundation of North Carolina
              (CFNC) to create the NCSSM electronic application. To complete an
              application, you must have a CFNC account. Go to www.cfnc.org read
              through the instructions and create a CFNC account. Once you have
              created an account or if you already have a CFNC account, log into
              CFNC.org.
              <br />
              <br />
              <i>
                For specific instructions on filling out the NCSSM Application
                at CFNC.org, see the Application Help Guide below.
              </i>
              <br />
              <br />
              <b>
                Applicants must have each section of the application completed
                via the CFNC.org electronic application by January 5, 2025, at
                11:59 p.m. There are no exceptions to this deadline.
              </b>
              <br />
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion defaultExpanded>
            <AccordionSummary
              style={{
                padding: "10px",
                backgroundColor: themeStyle.primaryButtonColor,
                borderRadius: "15px",
                fontWeight: 600,
                color: "white",
                fontSize: "25px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              4) Request Evaluation
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: "20px" }}>
            <br />

              <b>
                {" "}
                Standardized Test Update for the Class of 2027 The North
                Carolina School of Science and Mathematics will not require or
                accept standardized tests (SAT, ACT, etc.) for the Class of
                2027.
              </b>
              <br /> The Office of Admissions will not accept any score reports
              as part of the application process. If you have any questions,
              please contact the Office of Admissions, admissions@ncssm.edu.
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion defaultExpanded>
            <AccordionSummary
              style={{
                padding: "10px",
                backgroundColor: themeStyle.primaryButtonColor,
                borderRadius: "15px",
                fontWeight: 600,
                color: "white",
                fontSize: "25px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              5) Submit Transcripts
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: "20px" }}>
            <br />

              <b>
                {" "}
                Standardized Test Update for the Class of 2027 The North
                Carolina School of Science and Mathematics will not require or
                accept standardized tests (SAT, ACT, etc.) for the Class of
                2027.
              </b>
              <br /> The Office of Admissions will not accept any score reports
              as part of the application process. If you have any questions,
              please contact the Office of Admissions, admissions@ncssm.edu.
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px 50px 0px",
          }}
        >
          <Button
            onClick={()=>{
              navigate("/news_and_events");
            }}
            style={{
              padding: "40px",
              border:
                "2px " + state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor + " solid",
              borderRadius: "45px",
              height: "50px",
              fontSize: "20px",
              fontWeight: "bold",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryColor
                  : themeStyle.primaryColor,
            }}
          >
            MORE CAMPUS NEWS
          </Button>
        </div>

        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
