import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import VisitUs from "../components/VisitUs";
import { useCallback } from "react";
import axios from "axios";
import { API_URL } from "../actions/auth";
import { alertMessage } from "../actions/auth";

export default function AdmissionApply() {
  const state = useSelector((state) => state);
  const [studentName, setStudentName] = useState("");
  const [parentName, setParentName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [questions, setQuestions] = useState("");
  const [age, setAge] = useState("");
  const [classs, setClasss] = useState("LKG");

  function onSubmit(e) {
    e.preventDefault();
    var body={};
    body['student_name'] = studentName;
    body['parent_name'] = parentName;
    body['email'] = email;
    body['phone_number'] = phoneNumber;
    body['age'] = age;
    body['classs'] = classs;
    body['content'] = questions;

    axios
    .post(API_URL + "new_admission",JSON.stringify(body))
    .then((res) => {
        dispatch(alertMessage("SUCCESS", "SUCCESS", "Your request has been submitted. Our team will get back to you."));

    })
    .catch((err) => {});
  }

  const dispatch = useDispatch();

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://plus.unsplash.com/premium_photo-1678103472972-1cb2faa9fba9?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Admission Enquiry
          </div>
        </div>
        <div className="aboutabout" style={{marginTop:"100px"}}>
          <Grid container spacing={5}>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  fontSize: "55px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              >
                Ask us anything. Anytime.
              </div>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocationOnIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    BB Road, Chickballapur, 562101
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EmailIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    principal@bgsworldschool.ac.in
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    +91 7676637525
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Reception: 08156 200183
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Registrar: 08156 200185
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Principal: 08156 200184
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Account Section: 08156 200187
                  </div>
                </div>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                }}
                to={"/contact"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalPhoneIcon
                    className="nav-icon"
                    style={{
                      fontSize: 35,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Fax: 08156 200186
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7} style={{ justifyContent: "center" }}>
              <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="student name"
                      autoComplete="student name"
                      autoFocus
                      placeholder="Student Name *"
                      value={studentName}
                      onChange={(e) => {
                        setStudentName(e.target.value);

                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="parent name"
                      autoComplete="parent name"
                      autoFocus
                      placeholder="Parent Name *"
                      value={parentName}
                      onChange={(e) => {
                        setParentName(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Phone number *"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.backgroundColor
                            : themeStyle.backgroundColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                          {
                            color: themeStyle.primaryColor,
                            fontWeight: "bold",
                            opacity: 1,
                          },
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="age"
                      autoComplete="age"
                      autoFocus
                      placeholder="Student Age *"
                      value={age}
                      onChange={(e) => {
                        setAge(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Select
                      sx={{
                        marginTop: "16px",
                        width: "100%",
                        padding: "0px",
                        color: "black",
                        borderRadius: "6px",
                        borderColor: "blue",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid " + themeStyle.primaryColor,
                        },
                        "&:hover": {
                          "&& fieldset": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      value={classs}
                      onChange={(e) => {
                        setClasss(e.target.value);
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                      }}
                      displayEmpty
                    >
                      <MenuItem className="nav-menu-item" value="LKG">
                        LKG
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"SKG"}>
                        SKG
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 1"}>
                        Class 1
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 2"}>
                        Class 2
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 3"}>
                        Class 3
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 4"}>
                        Class 4
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 5"}>
                        Class 5
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 6"}>
                        Class 6
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 7"}>
                        Class 7
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 8"}>
                        Class 8
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 9"}>
                        Class 9
                      </MenuItem>
                      <MenuItem className="nav-menu-item" value={"Class 10"}>
                        Class 10
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <TextField
                  sx={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.backgroundColor
                        : themeStyle.backgroundColor,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                    borderRadius: "6px",
                    height: "400",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid " + themeStyle.primaryColor,
                    },
                    "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder":
                      {
                        color: themeStyle.primaryColor,
                        fontWeight: "bold",
                        opacity: 1,
                      },
                  }}
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  name="description"
                  autoComplete="description"
                  multiline
                  rows={7}
                  autoFocus
                  placeholder="Any other questions regarding admissions"
                  value={questions}
                  onChange={(e) => {
                    setQuestions(e.target.value);
                    dispatch({
                      type: "NAV_COLOR",
                      payload: { navColor: "#444", navOpacity: 1 },
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "60px",
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonColor
                          : themeStyle.primaryButtonColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryButtonTextColor
                          : themeStyle.primaryButtonTextColor,
                    }}
                    type="submit"
                    variant="contained"
                  >
                    <span
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                      }}
                    >
                      SUBMIT REQUEST
                    </span>
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
