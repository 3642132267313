import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import VisitUs from "../components/VisitUs";
import { gsap } from "gsap";



export default function Overview() {
  const state = useSelector((state) => state);

  const [selectedTab, setSelectedTab] = useState("RESIDENTIAL");

  useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
      gsap
        .to("#topic1", {
          position: "fixed",
          top: "25vh",
          marginTop: "0px",
          duration: 0,
          scrollTrigger: {
            start: 1950,
            trigger: "#intro",
            toggleActions: "play none none reverse",
          },
        })
        .reverse();
      gsap
        .to("#topic2", {
          position: "fixed",
          top: "35vh",
          duration: 0,
          scrollTrigger: {
            start: 2370,
            trigger: "#topic2",
            end: 10000,
            toggleActions: "play none none reverse",
          },
        })
        .reverse();
      gsap
        .to("#topic3", {
          position: "fixed",
          top: "45vh",
          duration: 0,
          scrollTrigger: {
            start: 2890,
            trigger: ".description",
            // pin: true,
            end: 10000,
            toggleActions: "play none none reverse",
          },
        })
        .reverse();
      gsap
        .to("#topic4", {
          position: "fixed",
          top: "55vh",
          duration: 0,
          scrollTrigger: {
            start: 3550,
            trigger: "#topic4",
            end: 10000,
            toggleActions: "play none none reverse",
          },
        })
        .reverse();

      gsap
        .to("#topic5", {
          position: "fixed",
          top: "65vh",
          duration: 0,
          scrollTrigger: {
            start: 4100,
            trigger: "#topic4",
            // pin: true,
            end: 10000,
            toggleActions: "play none none reverse",
          },
        })
        .reverse();

      gsap
        .to(".topic", {
          opacity: 0,
          duration: 0.25,
          scrollTrigger: {
            start: 4400,
            trigger: "#topic4",
            // pin: true,
            end: 2600,
            toggleActions: "play none none reverse",
          },
        })
        .reverse();
    });
  }, []);

  const dispatch = useDispatch();
  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1688632106590-39547edc1112?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Admissions in BGS World School
          </div>
        </div>
        <div style={{ padding: "100px 20% 100px 20%" }}>
          <Grid container spacing={10}>
            <Grid item xs={8}>
              <div style={{ fontSize: "35px", fontWeight: "600" }}>
                One School. Many Possibilites.
              </div>

              <p>
                BGS World School educates academically talented students to
                become state, national, and global leaders in science,
                technology, engineering, and mathematics. Founded as the
                nation’s first public, residential STEM high school, we let
                North Carolina students design your future through challenging
                programs driven by instructional excellence and the excitement
                of discovery.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Button
                style={{
                  border: "2px #005a32 solid",
                  borderRadius: "35px",
                  width: "100%",
                  height: "60px",
                  fontSize: "18px",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: "25px",
                  backgroundColor: "#005a32",
                  fontFamily: "Montserrat",
                }}
              >
                Apply Now
              </Button>
              <br />
              <Button
                style={{
                  border: "2px #d7301f solid",
                  borderRadius: "35px",
                  width: "100%",
                  height: "60px",
                  fontSize: "18px",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: "25px",
                  backgroundColor: "#d7301f",
                  fontFamily: "Montserrat",
                }}
              >
                Visit
              </Button>

              <Button
                style={{
                  border: "2px #4a1486 solid",
                  borderRadius: "35px",
                  width: "100%",
                  height: "60px",
                  fontSize: "18px",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: "25px",
                  backgroundColor: "#4a1486",
                  fontFamily: "Montserrat",
                }}
              >
                Request now
              </Button>
            </Grid>
          </Grid>
        </div>
        <div style={{ backgroundColor: themeStyle.primaryColor }}>
          <div style={{ padding: "100px 50px 100px 50px" }}>
            <Grid container spacing={1}>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <div
                  style={{
                    fontSize: "45px",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  Explore Our Programs
                </div>
                <Grid
                  container
                  spacing={10}
                  style={{
                    marginTop: "0px",
                  }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Button
                      onClick={() => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                        setSelectedTab("RESIDENTIAL");
                      }}
                      style={{
                        border:
                          selectedTab == "RESIDENTIAL"
                            ? "2px solid " + themeStyle.secondaryButtonColor
                            : "2px solid #ddd",
                        borderRadius: "35px",
                        width: "100%",
                        height: "60px",
                        fontSize: "18px",
                        color:
                          selectedTab == "RESIDENTIAL"
                            ? "white"
                            : themeStyle.secondaryButtonColor,
                        fontWeight: "bold",
                        marginBottom: "25px",
                        backgroundColor:
                          selectedTab == "RESIDENTIAL"
                            ? themeStyle.secondaryButtonColor
                            : "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      RESIDENTIAL
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Button
                      onClick={() => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                        setSelectedTab("VIRTUAL");
                      }}
                      style={{
                        border:
                          selectedTab == "VIRTUAL"
                            ? "2px solid " + themeStyle.secondaryButtonColor
                            : "2px solid #ddd",
                        borderRadius: "35px",
                        width: "100%",
                        height: "60px",
                        fontSize: "18px",
                        color:
                          selectedTab == "VIRTUAL"
                            ? "white"
                            : themeStyle.secondaryButtonColor,
                        fontWeight: "bold",
                        marginBottom: "25px",
                        backgroundColor:
                          selectedTab == "VIRTUAL"
                            ? themeStyle.secondaryButtonColor
                            : "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      VIRTUAL
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Button
                      onClick={() => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                        setSelectedTab("SUMMER");
                      }}
                      style={{
                        border:
                          selectedTab == "SUMMER"
                            ? "2px solid " + themeStyle.secondaryButtonColor
                            : "2px solid #ddd",
                        borderRadius: "35px",
                        width: "100%",
                        height: "60px",
                        fontSize: "18px",
                        color:
                          selectedTab == "SUMMER"
                            ? "white"
                            : themeStyle.secondaryButtonColor,
                        fontWeight: "bold",
                        marginBottom: "25px",
                        backgroundColor:
                          selectedTab == "SUMMER"
                            ? themeStyle.secondaryButtonColor
                            : "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      SUMMER
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Button
                      onClick={() => {
                        dispatch({
                          type: "NAV_COLOR",
                          payload: { navColor: "#444", navOpacity: 1 },
                        });
                        setSelectedTab("NOT SURE");
                      }}
                      style={{
                        border:
                          selectedTab == "NOT SURE"
                            ? "2px solid " + themeStyle.secondaryButtonColor
                            : "2px solid #ddd",
                        borderRadius: "35px",
                        width: "100%",
                        height: "60px",
                        fontSize: "18px",
                        color:
                          selectedTab == "NOT SURE"
                            ? "white"
                            : themeStyle.secondaryButtonColor,
                        fontWeight: "bold",
                        marginBottom: "25px",
                        backgroundColor:
                          selectedTab == "NOT SURE"
                            ? themeStyle.secondaryButtonColor
                            : "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      NOT SURE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 20% 100px 20%",
            }}
          >
            <div style={{ color: "white" }}>
              <div style={{ fontSize: "35px", fontWeight: "600" }}>
                Durham Residential{" "}
              </div>
              <br />
              <div style={{ fontSize: "25px", fontWeight: "600" }}>
                2024 Open House Dates
              </div>
              <p>
                Each year, 680 students live and learn in the two-year
                Residential program as high school juniors and seniors taking
                rigorous math, science, and humanities courses at NCSSM-Durham,
                situated among historic in-town neighborhoods within walking
                distance of Duke University in this mid-size city. The campus
                was founded in 1980 to create the nation’s first public,
                residential high school featuring STEM education.
              </p>
              <p>NCSSM-Morganton: Saturday, November 9, 2024</p>
              <p>
                Middle School Open House: Monday, November 11, 2024 (Morganton
                Campus)
              </p>
              <p>
                *Registration opens one month prior to each event. Registration
                is required.*
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "100px 50px 100px 50px",
            background:
              "linear-gradient(to bottom, #4178ba , #000080, #000080)",
          }}
        >
          <div
            style={{
              fontSize: "45px",
              color: "white",
              fontWeight: "600",
              textAlign: "center",
              margin: "0px 0px 150px 0px",
            }}
          >
            How to appy for admission
          </div>

          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="topic" id="topic1">
                1) Be eligible
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="description">
                <img
                  class="descimg"
                  src="https://global-uploads.webflow.com/62b5e5f0af534cc2238ff048/63160243cf6f3b0e6a51f519_Group%201000007307.png"
                />
                Applicants must be in their second year of high school
                <br />
                Applicants applying for the Class of 2027 must establish
                permanent legal residency in the state of North Carolina by
                December 1, 2024, and are required to complete the residency
                application using the North Carolina Residency Determination
                Services (RDS) by January 30, 2025.
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="topic" id="topic2">
                2) Pass Admission Tests
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="description">
                <img
                  className="descimg"
                  src="https://global-uploads.webflow.com/62b5e5f0af534cc2238ff048/6316021d12974b0b3acd8fd1_Group%201000007303%20(1).png"
                />
                <br />
                <b>
                  Standardized Test Update for the Class of 2027 The North
                  Carolina School of Science and Mathematics will not require or
                  accept standardized tests (SAT, ACT, etc.) for the Class of
                  2027.
                </b>
                <br /> The Office of Admissions will not accept any score
                reports as part of the application process. If you have any
                questions, please contact the Office of Admissions,
                admissions@ncssm.edu.
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="topic" id="topic3">
                3) Complete Application
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="description">
                <img
                  className="descimg"
                  src="https://global-uploads.webflow.com/62b5e5f0af534cc2238ff048/631601fcf920dd338e2e3584_Group%201000007302%20(2).png"
                />
                <br />
                NCSSM has partnered with the College Foundation of North
                Carolina (CFNC) to create the NCSSM electronic application. To
                complete an application, you must have a CFNC account. Go to
                www.cfnc.org read through the instructions and create a CFNC
                account. Once you have created an account or if you already have
                a CFNC account, log into CFNC.org.
                <br />
                <i>
                  For specific instructions on filling out the NCSSM Application
                  at CFNC.org, see the Application Help Guide below.
                </i>
                <br />
                <b>
                  Applicants must have each section of the application completed
                  via the CFNC.org electronic application by January 5, 2025, at
                  11:59 p.m. There are no exceptions to this deadline.
                </b>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="topic" id="topic4">
                4) Request Evaluation
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="description">
                <img
                  className="descimg"
                  src="https://global-uploads.webflow.com/62b5e5f0af534cc2238ff048/631601fcf920dd338e2e3584_Group%201000007302%20(2).png"
                />
                <br />
                <b>
                  {" "}
                  Standardized Test Update for the Class of 2027 The North
                  Carolina School of Science and Mathematics will not require or
                  accept standardized tests (SAT, ACT, etc.) for the Class of
                  2027.
                </b>
                <br /> The Office of Admissions will not accept any score
                reports as part of the application process. If you have any
                questions, please contact the Office of Admissions,
                admissions@ncssm.edu.
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="topic" id="topic5">
                5) Submit Transcripts
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="description">
                <img
                  className="descimg"
                  src="https://global-uploads.webflow.com/62b5e5f0af534cc2238ff048/631601fcf920dd338e2e3584_Group%201000007302%20(2).png"
                />
                <br />
                <b>
                  {" "}
                  Standardized Test Update for the Class of 2027 The North
                  Carolina School of Science and Mathematics will not require or
                  accept standardized tests (SAT, ACT, etc.) for the Class of
                  2027.
                </b>
                <br /> The Office of Admissions will not accept any score
                reports as part of the application process. If you have any
                questions, please contact the Office of Admissions,
                admissions@ncssm.edu.
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </div>
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
