import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../components/loading.json";
import { themeStyle, themeStyleDark } from "../components/Components";
import { gsap } from "gsap";
import Backdrop from "@mui/material/Backdrop";
import bg from "../bg.mp4";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisitUs from "../components/VisitUs";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";
import HistoryIcon from "@mui/icons-material/History";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SchoolIcon from "@mui/icons-material/School";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import RadarIcon from "@mui/icons-material/Radar";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import ClassIcon from "@mui/icons-material/Class";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Explore from "./Explore";
import Slider from "./Slider";



export default function Mission() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1589995779584-b67121dfa9fc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Mission and Vision{" "}
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          BGS Institute of Management Studies(BGS World School) was established in the
          year 2007 under the banner of Sri Adichunchanagiri Shikshana Trust ®
          to build enthusiastic quality professionals in the field of Management
          and Commerce. The Institute, over the NINE years of its existence has
          proved its academic discipline and quality oriented system of
          education and has the reputation of best academic results and
          placements in well known companies. BGS Institute of Management
          Studies is located in the BGS Campus of BB Road, Chickballapur on
          Bengaluru -Hyderabad national highway about 50 kms from Bengaluru and
          about 20 kms from Kempegowda International Airport, Bengaluru.{" "}
        </div>
      <Explore/>
        <Slider/>
        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
