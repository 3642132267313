import axios from "axios";
import { w3cwebsocket } from "websocket";

const PRODUCTION = true;

export const API_URL = PRODUCTION
  ? "https://bgsapi.gogoz.in/"
  : "http://127.0.0.1:8000/";

export const alertMessage = (type, title, message) => {
  return {
    type: "ALERT",
    payload: { type, title, message },
  };
};

export const refreshData = (page) => (dispatch, getState) => {
  var state = getState();
  var pages = state.auth.refresh;
  const index = pages.indexOf(page);
  if (index > -1) {
    pages.splice(index, 1);
  }
  dispatch({ type: "REFRESHED", payload: pages });
};

export const getNotifications = () => (dispatch, getState) => {
  var state = getState();
  var body = {
    email: state.auth.user.email,
  };
  const config = {
    headers: {
      Authorization: state.auth.token,
      "Content-Type": "application/json",
    },
  };
  // axios
  //   .post(PRODUCTION ? SERVER_API_ADDRESS + "get_user_unseen_notifications", body, config)
  //   .then((res) => {
  //     dispatch({
  //       type: "NOTIFICATIONS_LOADED",
  //       payload: res.data,
  //     });
  //   });
};

export const loadUser = () => (dispatch, getState) => {
  var state = getState();

  axios
    .get(API_URL + "api/auth/user", {
      headers: {
        Authorization: state.auth.token,
      },
    })
    .then((res) => {
      dispatch({
        type: "USER_LOADED",
        payload: res.data,
      });

      state = getState();
      // const config = {
      //   headers: {
      //     Authorization: state.auth.token,
      //     "Content-Type": "application/json",
      //   },
      // };
      // const client = new w3cwebsocket(
      //   PRODUCTION
      //     ? SERVER_SOCKET_ADDRESS +
      //       "ws/chat/" +
      //       state.auth.user.channel_id +
      //       "/"
      //     : LOCAL_SOCKET_ADDRESS + "ws/chat/" + state.auth.user.channel_id + "/"
      // );
      // dispatch({
      //   type: "SOCKET_LOADED",
      //   payload: client,
      // });
    })
    .catch((err) => {
      dispatch({
        type: "AUTH_ERROR",
      });
    });
};

// LOGIN USER
export const login = (email, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({
    email: email,
    password: password,
    fireToken: "web",
  });

  axios
    .post(API_URL + "api/auth/login", body, config)
    .then((res) => {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      });

      dispatch(loadUser());
      dispatch(alertMessage("SUCCESS", "Welcome!", "Login success."));
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch(
        alertMessage(
          "ERROR",
          err.response.status,
          err.response.data.non_field_errors[0]
        )
      );
      dispatch({
        type: "LOGIN_FAIL",
      });
    });
};

export const sendReset = (email) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ email });

  axios
    .post(API_URL + "api/password_reset/", body, config)
    .then((res) => {
      dispatch(
        alertMessage(
          "SUCCESS",
          "Email Sent.",
          "Check your email account for password reset link."
        )
      );
    })
    .catch((err) => {
      dispatch(
        alertMessage(
          "ERROR",
          err.response.status,
          Object.values(err.response.data)[0][0]
        )
      );
    });
};

export const passwordReset = (token, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ token, password });

  axios
    .post(API_URL + "api/password_reset/confirm/", body, config)
    .then((res) => {
      dispatch({
        type: "PASSWORD_RESET",
        payload: res.data,
      });
      dispatch(
        alertMessage("SUCCESS", "Success!", "Password was reset successfully.")
      );
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch(
        alertMessage("ERROR", err.response.status, "Incorrect reset key.")
      );
    });
};

export const logout = () => (dispatch, getState) => {
  var state = getState();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  config.headers["Authorization"] = state.auth.token;

  axios
    .post(API_URL + "api/auth/logout", null, config)
    .then(() => {
      dispatch(
        alertMessage("SUCCESS", "Logout success.", "You have been logged out.")
      );
      dispatch({ type: "LOGOUT_SUCCESS" });
    })
    .catch((err) => {
      dispatch(
        alertMessage("SUCCESS", "Logout success.", "You have been logged out.")
      );
      dispatch({ type: "AUTH_ERROR" });
    });
};
