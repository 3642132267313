import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeStyle, themeStyleDark } from "../components/Components";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import VisitUs from "../components/VisitUs";
import Button from "@mui/material/Button";
import CountUp from "react-countup";
import Benchmark from "./Benchmark";


export default function StudentLife() {
  const state = useSelector((state) => state);

  useEffect(() => {}, []);

  const [selectedTab, setSelectedTab] = useState("STUDENT LIFE");

  function getContent() {
    return (
      <div className="main">
        <Sidebar />
        <div className="overlay"></div>

        <div
          className="abouthero"
          style={{
            background:
              "linear-gradient(0deg, rgba(2, 77, 194, 0.2), rgba(2, 77, 194, 0.2)), url('https://images.unsplash.com/photo-1551731409-43eb3e517a1a?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          }}
        >
          <div
            style={{
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryButtonTextColor
                  : themeStyle.primaryButtonTextColor,
            }}
          >
            Student Life
          </div>
        </div>
        <div
          className="aboutabout"
          style={{ fontSize: "22px", marginBottom: "80px", marginTop:"100px" }}
        >
          <b>
            At BGS World School, we offer students the opportunity to explore
            the world of commerce and business administration through our B.Com,
            BBA, and M.Com programs. Our diverse student body comprises
            individuals who are eager to delve deep into these fields, equipped
            with a keen sense of curiosity and a drive for success. The academic
            rigor of our programs, coupled with the supportive learning
            environment we foster, allows our students to thrive and reach their
            full potential.
          </b>
          <br />
          <br />
          Our students are not just numbers, but valued members of our academic
          community. Whether they’re working through complex financial problems,
          creating innovative business strategies, or conducting in-depth
          research, our students demonstrate their dedication and commitment to
          their education every day. Outside the classroom, they engage in a
          variety of extracurricular activities, contributing to a vibrant
          campus life. The skills and experiences they gain at BGSIMS prepare
          them to make a significant impact in their chosen fields upon
          graduation.
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Grid
            container
            style={{
              marginTop: "0px",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Button
                onClick={() => setSelectedTab("STUDENT LIFE")}
                style={{
                  border:
                    selectedTab == "STUDENT LIFE"
                      ? "2px solid " + themeStyle.secondaryButtonColor
                      : "2px solid #ddd",
                  borderTopLeftRadius: "35px",
                  borderBottomLeftRadius: "35px",

                  width: "100%",
                  height: "60px",
                  fontSize: "18px",
                  color:
                    selectedTab == "STUDENT LIFE"
                      ? "white"
                      : themeStyle.secondaryButtonColor,
                  fontWeight: "bold",
                  marginBottom: "25px",
                  backgroundColor:
                    selectedTab == "STUDENT LIFE"
                      ? themeStyle.secondaryButtonColor
                      : "white",
                  fontFamily: "Montserrat",
                }}
              >
                STUDENT LIFE
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Button
                onClick={() => setSelectedTab("ACTIVITIES")}
                style={{
                  border:
                    selectedTab == "ACTIVITIES"
                      ? "2px solid " + themeStyle.secondaryButtonColor
                      : "2px solid #ddd",
                  borderTopRightRadius: "35px",
                  borderBottomRightRadius: "35px",
                  width: "100%",
                  height: "60px",
                  fontSize: "18px",
                  color:
                    selectedTab == "ACTIVITIES"
                      ? "white"
                      : themeStyle.secondaryButtonColor,
                  fontWeight: "bold",
                  marginBottom: "25px",
                  backgroundColor:
                    selectedTab == "ACTIVITIES"
                      ? themeStyle.secondaryButtonColor
                      : "white",
                  fontFamily: "Montserrat",
                }}
              >
                ACTIVITIES
              </Button>
            </Grid>
          </Grid>
        </div>


        {selectedTab == "ACTIVITIES" ? (
          <div className="aboutabout2" style={{ marginTop: "-150px" }}>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Developing Leadership and Social Skills
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    At BGS World School, student life emphasizes the development
                    of leadership and social skills that are crucial for future
                    success. Through initiatives like student councils, clubs,
                    and peer mentoring programs, students are given
                    opportunities to take on leadership roles and contribute to
                    the school community. These experiences help students build
                    confidence, enhance communication skills, and learn the
                    value of responsibility. Group projects, collaborative
                    learning, and community service activities further reinforce
                    teamwork and empathy. By participating in these initiatives,
                    students develop essential life skills that prepare them to
                    take on challenges with a positive, solution-oriented
                    mindset, both within the school environment and beyond.
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    A Supportive and Inclusive Environment
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    BGS World School is committed to creating a nurturing and
                    inclusive environment where every student feels valued and
                    supported. The school fosters a sense of belonging by
                    promoting diversity and encouraging students to respect and
                    appreciate different cultures and perspectives. Student life
                    is enriched by various programs aimed at promoting emotional
                    well-being, such as counseling services, mindfulness
                    sessions, and wellness workshops. Teachers and staff provide
                    personalized guidance to ensure that every student feels
                    safe and supported, both academically and personally. This
                    supportive atmosphere allows students to grow emotionally,
                    socially, and intellectually, empowering them to become
                    well-rounded individuals who are ready to contribute to
                    society.
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Developing Leadership and Social Skills
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    At BGS World School, student life emphasizes the development
                    of leadership and social skills that are crucial for future
                    success. Through initiatives like student councils, clubs,
                    and peer mentoring programs, students are given
                    opportunities to take on leadership roles and contribute to
                    the school community. These experiences help students build
                    confidence, enhance communication skills, and learn the
                    value of responsibility. Group projects, collaborative
                    learning, and community service activities further reinforce
                    teamwork and empathy. By participating in these initiatives,
                    students develop essential life skills that prepare them to
                    take on challenges with a positive, solution-oriented
                    mindset, both within the school environment and beyond.
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    A Vibrant and Engaging Campus Life
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Student life at BGS World School is dynamic and enriching,
                    offering students a well-rounded experience that goes beyond
                    academics. From a young age, students are encouraged to
                    participate in a wide variety of extracurricular activities,
                    including sports, arts, music, and drama. These activities
                    are designed to help students discover their passions and
                    talents while fostering creativity, teamwork, and
                    leadership. The school also organizes various cultural and
                    academic events, such as inter-school competitions, science
                    fairs, and festivals, where students can showcase their
                    skills and collaborate with peers. This vibrant campus life
                    ensures that students are actively engaged, making their
                    school experience enjoyable and fulfilling.
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Developing Leadership and Social Skills
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    At BGS World School, student life emphasizes the development
                    of leadership and social skills that are crucial for future
                    success. Through initiatives like student councils, clubs,
                    and peer mentoring programs, students are given
                    opportunities to take on leadership roles and contribute to
                    the school community. These experiences help students build
                    confidence, enhance communication skills, and learn the
                    value of responsibility. Group projects, collaborative
                    learning, and community service activities further reinforce
                    teamwork and empathy. By participating in these initiatives,
                    students develop essential life skills that prepare them to
                    take on challenges with a positive, solution-oriented
                    mindset, both within the school environment and beyond.
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    A Vibrant and Engaging Campus Life
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    Student life at BGS World School is dynamic and enriching,
                    offering students a well-rounded experience that goes beyond
                    academics. From a young age, students are encouraged to
                    participate in a wide variety of extracurricular activities,
                    including sports, arts, music, and drama. These activities
                    are designed to help students discover their passions and
                    talents while fostering creativity, teamwork, and
                    leadership. The school also organizes various cultural and
                    academic events, such as inter-school competitions, science
                    fairs, and festivals, where students can showcase their
                    skills and collaborate with peers. This vibrant campus life
                    ensures that students are actively engaged, making their
                    school experience enjoyable and fulfilling.
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <div className="aboutabout2" style={{ marginTop: "-150px" }}>

            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    A Supportive and Inclusive Environment
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    BGS World School is committed to creating a nurturing and
                    inclusive environment where every student feels valued and
                    supported. The school fosters a sense of belonging by
                    promoting diversity and encouraging students to respect and
                    appreciate different cultures and perspectives. Student life
                    is enriched by various programs aimed at promoting emotional
                    well-being, such as counseling services, mindfulness
                    sessions, and wellness workshops. Teachers and staff provide
                    personalized guidance to ensure that every student feels
                    safe and supported, both academically and personally. This
                    supportive atmosphere allows students to grow emotionally,
                    socially, and intellectually, empowering them to become
                    well-rounded individuals who are ready to contribute to
                    society.
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Developing Leadership and Social Skills
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    At BGS World School, student life emphasizes the development
                    of leadership and social skills that are crucial for future
                    success. Through initiatives like student councils, clubs,
                    and peer mentoring programs, students are given
                    opportunities to take on leadership roles and contribute to
                    the school community. These experiences help students build
                    confidence, enhance communication skills, and learn the
                    value of responsibility. Group projects, collaborative
                    learning, and community service activities further reinforce
                    teamwork and empathy. By participating in these initiatives,
                    students develop essential life skills that prepare them to
                    take on challenges with a positive, solution-oriented
                    mindset, both within the school environment and beyond.
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    A Supportive and Inclusive Environment
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    BGS World School is committed to creating a nurturing and
                    inclusive environment where every student feels valued and
                    supported. The school fosters a sense of belonging by
                    promoting diversity and encouraging students to respect and
                    appreciate different cultures and perspectives. Student life
                    is enriched by various programs aimed at promoting emotional
                    well-being, such as counseling services, mindfulness
                    sessions, and wellness workshops. Teachers and staff provide
                    personalized guidance to ensure that every student feels
                    safe and supported, both academically and personally. This
                    supportive atmosphere allows students to grow emotionally,
                    socially, and intellectually, empowering them to become
                    well-rounded individuals who are ready to contribute to
                    society.
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Developing Leadership and Social Skills
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    At BGS World School, student life emphasizes the development
                    of leadership and social skills that are crucial for future
                    success. Through initiatives like student councils, clubs,
                    and peer mentoring programs, students are given
                    opportunities to take on leadership roles and contribute to
                    the school community. These experiences help students build
                    confidence, enhance communication skills, and learn the
                    value of responsibility. Group projects, collaborative
                    learning, and community service activities further reinforce
                    teamwork and empathy. By participating in these initiatives,
                    students develop essential life skills that prepare them to
                    take on challenges with a positive, solution-oriented
                    mindset, both within the school environment and beyond.
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    A Supportive and Inclusive Environment
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    BGS World School is committed to creating a nurturing and
                    inclusive environment where every student feels valued and
                    supported. The school fosters a sense of belonging by
                    promoting diversity and encouraging students to respect and
                    appreciate different cultures and perspectives. Student life
                    is enriched by various programs aimed at promoting emotional
                    well-being, such as counseling services, mindfulness
                    sessions, and wellness workshops. Teachers and staff provide
                    personalized guidance to ensure that every student feels
                    safe and supported, both academically and personally. This
                    supportive atmosphere allows students to grow emotionally,
                    socially, and intellectually, empowering them to become
                    well-rounded individuals who are ready to contribute to
                    society.
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1709805619372-40de3f158e83?q=80&w=1495&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="aboutabout2" style={{ marginTop: "-150px" }}>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      margin: "10px",
                    }}
                    src={
                      "https://images.unsplash.com/photo-1555854877-bab0e564b8d5?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <div
                    style={{
                      fontSize: "28px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Developing Leadership and Social Skills
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: "22px",
                      fontFamily: "Montserrat",
                      textAlign: "left",
                    }}
                  >
                    At BGS World School, student life emphasizes the development
                    of leadership and social skills that are crucial for future
                    success. Through initiatives like student councils, clubs,
                    and peer mentoring programs, students are given
                    opportunities to take on leadership roles and contribute to
                    the school community. These experiences help students build
                    confidence, enhance communication skills, and learn the
                    value of responsibility. Group projects, collaborative
                    learning, and community service activities further reinforce
                    teamwork and empathy. By participating in these initiatives,
                    students develop essential life skills that prepare them to
                    take on challenges with a positive, solution-oriented
                    mindset, both within the school environment and beyond.
                  </div>
                </Grid>
              </Grid>
            </div>

          </div>
        )}

        <Benchmark />
        <VisitUs />
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          state.auth.theme == "dark"
            ? themeStyleDark.backgroundColor
            : themeStyle.backgroundColor,
        transition: "opacity 2s",
      }}
    >
      {getContent()}
    </div>
  );
}
