import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function ScrollTop() {
  return (
    <div
      style={{
        height: "75px",
        width: "75px",
        borderRadius: "40px",
        backgroundColor: "black",
        position: "fixed",
        right: 20,
        zIndex: 4,
        bottom: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        cursor:"pointer"
      }}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className="takemeup"
    >
      <ArrowUpwardIcon style={{ color: "white", fontSize: "40px" }} />
    </div>
  );
}
