import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import AboutSchool from "./pages/AboutSchool";
import Contact from "./pages/Contact";
import Classroom from "./pages/Classroom";
import Logout from "./pages/Logout";
import AdmissionApply from "./pages/AdmissionApply";
import Oops from "./pages/Oops";
import Register from "./pages/Register";
import Facilities from "./pages/Facilities";
import BoysHostel from "./pages/BoysHostel";
import GirlsHostel from "./pages/GirlsHostel";
import Library from "./pages/Library";
import ComputerLabs from "./pages/ComputerLabs";
import Transportation from "./pages/Transportation";
import Playground from "./pages/Playground";
import Overview from "./pages/Overview";
import HowToApply from "./pages/HowToApply";
import { loadUser } from "./actions/auth";
import Gallery from "./pages/Gallery";
import StudentLife from "./pages/StudentLife";
import CampusExperience from "./pages/CampusExperience";
import CAO from "./pages/CAO";
import Mission from "./pages/Mission";
import Principal from "./pages/Principal";
import Leadership from "./pages/Leadership";
import News from "./pages/News";
import Alumni from "./pages/Alumni";
import NewsPage from "./pages/NewsPage";
import Sports from "./pages/Sports";
import SportPage from "./pages/SportPage";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    // dispatch(loadUser());
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (state.auth.loginFailed) {
    // navigate("/", { replace: true });
    // dispatch({ type: "RESET_REDIRECT_LOGIN" });
  }

  function handleWindowSizeChange() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 1300) {
      dispatch({ type: "MOBILE_VIEW", payload: true });
    } else {
      dispatch({ type: "MOBILE_VIEW", payload: false });
    }
  }

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/about" element={<About />} />
      <Route path="/classroom" element={<Classroom />} />
      <Route path="/boys_hostel" element={<BoysHostel />} />
      <Route path="/girls_hostel" element={<GirlsHostel />} />
      <Route path="/computer_labs" element={<ComputerLabs />} />
      <Route path="/transportation" element={<Transportation />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/library" element={<Library />} />
      <Route path="/facilities" element={<Facilities />} />
      <Route path="/about_school" element={<AboutSchool />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/apply" element={<AdmissionApply />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/overview" element={<Overview />} />
      <Route path="/how_to_apply" element={<HowToApply />} />
      <Route path="/student_life" element={<StudentLife />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/campus" element={<CampusExperience />} />
      <Route path="/leadership" element={<Leadership />} />
      <Route path="/principal" element={<Principal />} />
      <Route path="/mission" element={<Mission />} />
      <Route path="/cao" element={<CAO />} />
      <Route path="/news_and_events" element={<News />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/alumni" element={<Alumni />} />
      <Route path="/sports" element={<Sports />} />
      <Route path="/sports_info" element={<SportPage />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;
