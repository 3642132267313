import React from "react";
import { themeStyle, themeStyleDark } from "./Components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link, Navigate, useNavigate } from "react-router-dom";



export default function VisitUs(props) {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  return (
    <div
          className="aboutabout3"
          style={{
            backgroundColor: "#161616",
            color:
              state.auth.theme == "dark"
                ? themeStyleDark.primaryButtonTextColor
                : themeStyle.primaryButtonTextColor,
          }}
        >
          <div
            style={{
              marginTop: "-100px",
              fontSize: "55px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
            }}
          >
            COME VISIT OUR CAMPUS
          </div>
          <br />
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              marginBottom: "30px",
            }}
          >
            Are you ready to take the next step toward your future career?
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid container spacing={5} style={{ width: "70%" }}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Link
                  style={{
                    textDecoration: "none",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonTextColor
                        : themeStyle.primaryButtonTextColor,
                  }}
                  to={"/contact"}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhoneIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "5px",
                        fontSize: "30px",
                        fontFamily: "Acherus Grotesque Regular",
                      }}
                    >
                      +91 7676637525
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Link
                  style={{
                    textDecoration: "none",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonTextColor
                        : themeStyle.primaryButtonTextColor,
                  }}
                  to={"/contact"}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <EmailIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "5px",
                        fontSize: "30px",
                        fontFamily: "Acherus Grotesque Regular",
                      }}
                    >
                      mbainfo@bgsws.ac.in
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Link
                  style={{
                    textDecoration: "none",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryButtonTextColor
                        : themeStyle.primaryButtonTextColor,
                  }}
                  to={"/contact"}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LinkedInIcon
                      className="nav-icon"
                      style={{
                        fontSize: 35,
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "5px",
                        fontSize: "30px",
                        fontFamily: "Acherus Grotesque Regular",
                      }}
                    >
                      LinkedIn
                    </div>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "50px 0px 100px 0px",
            }}
          >
            <Button
              onClick={()=>{
                navigate("/news_and_events");
              }}
              style={{
                padding: "40px",
                border:
                  "2px " + state.auth.theme == "dark"
                    ? themeStyleDark.primaryButtonTextColor
                    : themeStyle.primaryButtonTextColor + " solid",
                borderRadius: "45px",
                height: "50px",
                fontSize: "20px",
                fontWeight: "bold",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryButtonTextColor
                    : themeStyle.primaryButtonTextColor,
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              }}
            >
              MORE CAMPUS NEWS
            </Button>
          </div>
        </div>
  );
}
